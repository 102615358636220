define('digital-style/components/comic-list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        actions: {
            addPanel: function addPanel(nextPanelId) {
                //console.log('comic-list addPanel');
                //console.log(nextPanelId);

                // bubble up the action addPanel - will go to story's controller
                this.sendAction('addPanel', nextPanelId);
            },
            recipeHandler: function recipeHandler(nextPanelId) {
                console.log('comic-list recipeHandler');
                console.log(nextPanelId);

                // bubble up the action addPanel - will go to story's controller
                this.sendAction('recipeHandler', nextPanelId);
            }
        }
    });
});