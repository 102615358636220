define('digital-style/controllers/comic/darby/c1', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({
        needs: ['application'],
        actions: {
            addPanel: function addPanel(nextPanelId, time) {
                //console.log('story addPanel');
                //             console.log(time);

                // set this; get the character selected in the application's controller
                var t = this,
                    idx,
                    opts,
                    img,
                    imgName,
                    panelcls,
                    intx,
                    times = !!time ? parseInt(time, 10) + 1 : 1,
                    isSecondTime = times === 2;

                document.body.style.background = '#2e343e';

                // find the the store that matches the character selected, then...
                this.store.find('darby/c1', nextPanelId).then(function (arr) {
                    idx = arr.get('id');
                    opts = arr.get('paths');
                    img = arr.get('imgurl');
                    imgName = img.split('/').pop().split('.');
                    imgName.splice(-1, 1);
                    panelcls = imgName.join('').split('-')[0];

                    // add new panel to the comic model
                    t.store.createRecord('comic', {
                        id: idx,
                        type: arr.get('type'),
                        imgurl: arr.get('imgurl'),
                        cls: panelcls,
                        paths: opts
                    }).save();

                    setTimeout(function () {
                        // load next panel if there's only one path
                        if (opts.length === 1) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();

                            // send action to add next panel
                            t.send('addPanel', arr.get('paths')[0]['path'], times);

                            if (isSecondTime) {
                                // flash scroll notification
                                intx = setInterval(function () {
                                    $('#comic > .scroll-notify').fadeToggle(250);
                                }, 500);

                                // stop flashing
                                setTimeout(function () {
                                    clearInterval(intx);
                                }, 3500);
                            }
                        } else if (opts.length === 0) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();
                        }

                        if (arr.get('type') === 'end') {
                            $('#story #retrospective').show();
                        }
                    }, 100);
                });
            },
            //Uses JQuery and Jquery Waypoints to do detection.
            setCurrentPage: function setCurrentPage() {

                var notified = false;
                var documentHeightAtFirstChoice;
                //uses scroll detection to detect whether the user is mast a certain point on the page such that the information to scroll needs to show up
                $(window).scroll(function () {
                    if (notified === false) {
                        var documentHeight = $(document).height() - 300;

                        if ($(window).scrollTop() + $(window).height() >= documentHeight) {
                            notified = true;
                            documentHeightAtFirstChoice = $(document).height();
                            console.log(documentHeightAtFirstChoice);

                            //Below is the Code for Drop in Notification

                            /*
                            						var bottomPos = $(document).height() - $(window).height()-225;
                            						console.log(bottomPos);
                            
                            						document.getElementById("notificationBanner").style.display = 'inline';
                            						document.getElementById("notificationBanner").style.top = bottomPos + "px";
                            
                            						$("#notificationBanner").delay(100).animate({top: "+=155"}, 1000, function(){
                            							document.getElementById("notificationBanner").style.position = 'fixed';
                            							document.getElementById("notificationBanner").style.top = 0;
                            						});
                            
                            */
                            //Below is fade in animation
                            $("#notificationBanner").fadeIn(1000);

                            // getData();
                        }
                    }

                    //once the notification shows up and the users clicks, this will make the banner hide again
                    if (notified === true) {
                        if ($(document).height() >= documentHeightAtFirstChoice + 100) {
                            $("#notificationBanner").fadeOut(1000);
                        }
                    }
                });
            }

        }
    });
});
/*globals $ */