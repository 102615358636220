define('digital-style/router', ['exports', 'ember', 'digital-style/config/environment', 'digital-style/mixins/google-pageview'], function (exports, _ember, _digitalStyleConfigEnvironment, _digitalStyleMixinsGooglePageview) {

  var Router = _ember['default'].Router.extend(_digitalStyleMixinsGooglePageview['default'], {
    location: _digitalStyleConfigEnvironment['default'].locationType

  });

  // defining all routes
  Router.map(function () {
    // intro routes
    this.resource('intro', function () {
      this.route('intro-1');
      this.route('intro-2');
      this.route('intro-3');
      this.route('intro-4');
      this.route('intro-5');
      this.route('intro-5b');
    });

    // comic routes
    this.resource('comic', function () {
      this.route('story');

      this.route('darby', function () {
        this.route('c1');
        this.route('c2');
        this.route('c2-pc');
        this.route('c2-junk');
        this.route('c2-housecraft');
        this.route('c2-antivirus');
        this.route('c2-blog');
        this.route('c2-internets-robogle');
        this.route('c2-internets-housecraft');
        this.route('c2-internets-blog');
        this.route('c2-internets-dapple');
        this.route('c2-internets-dapple-pc');
        this.route('c2-internets-dapple-phone');
        this.route('c2-internets-dapple-support');
        this.route('c2-internets-translate');

        this.route('c2-translate');
        this.route('c3');
        this.route('c4-blaster');
        this.route('c4-shield');

        this.route('c5-shield');
        this.route('c5-blaster');
      });

      this.route('ellen', function () {
        this.route('01-prologue');

        this.route('02-goodsleep');
        this.route('02-badsleep');

        this.route('03-good-help');
        this.route('03-good-reject');
        this.route('03-bad-reject');
        this.route('03-bad-help');

        this.route('04-help-homework');
        this.route('04-help-text');
        this.route('04-reject-homework');
        this.route('04-reject-text');
      });
      this.route('spaghetti', function () {
        this.route('c1');
        this.route('c2');
        this.route('c3');
        this.route('c4');
      });
    });

    // retrospective routes
    // ----> create retrospective route here <----
    this.resource('retro', function () {
      this.route('retro-1');
      this.route('retro-2');
      this.route('retro-3');
      this.route('retro-end');
    });

    this.route('site', function () {
      this.route('main');
      this.route('darby-chp');
      this.route('module-picker');
      this.route('darby-main');
      this.route('ellen-main');
      this.route('ellen-chp');
      this.route('spaghetti-main');
      this.route('spaghetti-chp');
      this.route('characters');
      this.route('teachers');
      this.route('profile', function () {
        this.route('ellen');
        this.route('patoo');
        this.route('darby');
        this.route('cathy');
        this.route('bolt');
        this.route('shade');
      });
    });
  }).reopen({
    // there're 3 different kind of locations:
    // - history: mywebapp.com/route/content
    // - hash: mywebappp.com/#route/content
    // - none: mywebapp.com/
    location: 'none'
  });

  exports['default'] = Router;
});