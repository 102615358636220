define('digital-style/controllers/comic/spaghetti/spaghetti-main', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({
        needs: ['application'],
        scrollInstructions: false,
        position: { x: 0, y: 0 },
        modelToUse: '',
        actions: {
            addPanel: function addPanel(nextPanelId, time) {

                var t = this,

                //                 c = t.get('charId'),
                c = t.get('modelToUse'),
                    idx,
                    opts,
                    img,
                    imgName,
                    panelcls,
                    intx,
                    times = !!time ? parseInt(time, 10) + 1 : 1,
                    isSecondTime = times === 2;

                // find the the store that matches the character selected, then...
                this.store.find(c, nextPanelId).then(function (arr) {
                    idx = arr.get('id');
                    opts = arr.get('paths');
                    img = arr.get('imgurl');
                    imgName = img.split('/').pop().split('.');
                    imgName.splice(-1, 1);
                    panelcls = imgName.join('').split('-')[0];

                    // add new panel to the comic model
                    t.store.createRecord('comic', {
                        id: idx,
                        type: arr.get('type'),
                        imgurl: arr.get('imgurl'),
                        cls: panelcls,
                        paths: opts
                    }).save();

                    setTimeout(function () {
                        // load next panel if there's only one path
                        if (opts.length === 1) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();

                            // send action to add next panel
                            t.send('addPanel', arr.get('paths')[0]['path'], times);

                            if (isSecondTime) {
                                // flash scroll notification
                                intx = setInterval(function () {
                                    $('#comic > .scroll-notify').fadeToggle(250);
                                }, 500);

                                // stop flashing
                                setTimeout(function () {
                                    clearInterval(intx);
                                }, 3500);
                            }
                        } else if (opts.length === 0) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();
                        }

                        if (arr.get('type') === 'end') {
                            $('#story #retrospective').show();
                        }

                        if (t.get('scrollInstructions') === false) {
                            _ember['default'].$("#topPasswordOrInstructions").animate({ top: "+=200" }, 1000);
                            t.set('scrollInstructions', true);
                        }
                    }, 100);
                });
            },

            // isScrolledIntoView:function(trigger, elem){

            topBannerScrollHandler: function topBannerScrollHandler() {
                if (_ember['default'].$(window).scrollTop() > 200) {
                    _ember['default'].$("#topPasswordOrInstructions").fadeOut();
                }
                // console.log("#panel-5".offsetHeight);
            },

            spaghettiJournalScrollHandler: function spaghettiJournalScrollHandler(panels) {
                if (_ember['default'].$(".patooJournal").is(":visible")) {
                    console.log(window.scrollY);
                    window.scrollTo(this.get('position.x'), this.get('position.y'));
                } else {
                    this.set('position.x', window.scrollX);
                    this.set('position.y', window.scrollY);
                }
                var t = this;
                setTimeout(function () {
                    t.store.find('spaghetti', 1).then(function (arr) {
                        // var page = arr.get('page'+nextPg);
                        panels.forEach(function (id /*, index*/) {
                            if (isScrolledIntoView(id)) {
                                console.log("journaled0");
                                console.log(id);
                                if ((id === "#panel-2-bump" || id === "#panel-2-hf" || id === "#panel-2-spaghetti" || id === "#panel-3-spaghetti") && arr.get('spaghetti') === 'false') {
                                    arr.set('spaghetti', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    _ember['default'].$(".toggleJournalBtn").fadeIn(1000);
                                    //delay(1000)
                                    // t.send('unlockPage');
                                    console.log("journaled1");
                                } else if (id === "#panel-9-plate" && arr.get('plate') === 'false') {
                                    arr.set('spaghetti', 'true');
                                    arr.set('plate', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    _ember['default'].$(".toggleJournalBtn").fadeIn(1000);
                                    console.log("journaled2");
                                } else if (id === "#panel-26-fruits" && arr.get('fruits') === 'false') {
                                    // arr.set('spaghetti', 'true');
                                    // arr.set('plate', 'true');
                                    arr.set('fruits', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    console.log("journaled3");
                                } else if ((id === "#panel-28-grains" || id === "#panel-29-grains") && arr.get('grains') === 'false') {
                                    arr.set('fruits', 'true');
                                    arr.set('grains', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    console.log("journaled4");
                                } else if (id === "#panel-30-dairy" && arr.get('dairy') === 'false') {
                                    arr.set('fruits', 'true');
                                    arr.set('grains', 'true');
                                    arr.set('dairy', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    console.log("journaled5");
                                } else if (id === "#panel-33-protein" && arr.get('protein') === 'false') {
                                    arr.set('fruits', 'true');
                                    arr.set('grains', 'true');
                                    arr.set('dairy', 'true');
                                    arr.set('protein', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    console.log("journaled6");
                                } else if (id === "#panel-8-water" && arr.get('water') === 'false') {
                                    arr.set('water', 'true');
                                    _ember['default'].$("#journalEntryNotification").fadeIn(1000).delay(5000).fadeOut(1000);
                                    console.log("journaled7");
                                }
                                //
                            }
                            // else{
                            //     Ember.$("#journalEntryNotification").delay(5000).fadeOut(1000);
                            //     console.log("fadeOut")
                            // }
                            // else{
                            //     Ember.$("#journalEntryNotification").fadeOut(1000);
                            // }
                        });
                    });
                }, 1000);
            }
        }

    });
    // showJournal: function(){

    // },

    // unlockPage: function(){

    //     console.log("test")
    // },

    function isScrolledIntoView(elem) {
        if ($(elem).length === 1) {
            //                          console.log('here');
            var docViewTop = _ember['default'].$(window).scrollTop() - 400;
            // console.log("top "+Ember.$(window).scrollTop());
            // console.log("top-200 "+docViewTop);
            // var docBot = docViewTop + Ember.$(window).height();
            var docViewBottom = docViewTop + _ember['default'].$(window).height() + 400;
            // console.log("bottom "+ docBot);
            // console.log("bottom + 200 "+docViewBottom);
            var elemTop = _ember['default'].$(elem).offset().top;
            var elemBottom = elemTop + _ember['default'].$(elem).height();
            return elemBottom >= docViewTop && elemTop <= docViewBottom && elemBottom <= docViewBottom && elemTop >= docViewTop;
        }
    }
});
/*globals $ */