define('digital-style/models/ellen/03-bad-help', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_3_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 265
            }]
        }, {
            id: 265,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 199
            }]
        }, {
            id: 199,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 200
            }]
        }, {
            id: 200,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 201
            }]
        }, {
            id: 201,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 240
            }]
        }, {
            id: 240,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream1.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 241
            }]
        }, {
            id: 241,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream2.png",
            type: '',
            paths: [{
                cta: null,
                path: 202
            }]
        }, {
            id: 202,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 203
            }]
        }, {
            id: 203,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 204
            }]
        }, {
            id: 204,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 205
            }]
        }, {
            id: 205,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_nosleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 206
            }]
        }, {
            id: 206,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 207
            }]
        }, {
            id: 207,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a.png",
            type: '',
            paths: [{
                cta: 'Be quiet!',
                path: 208
            }, {
                cta: 'Ignore.',
                path: 209
            }]
        }, {
            id: 208,
            points: 0,
            imgurl: "assets/img/ellen/ellen_getlostpippy.png",
            type: '',
            paths: [{
                cta: null,
                path: 209
            }]
        }, {
            id: 209,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 210
            }]
        }, {
            id: 210,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 211
            }]
        }, {
            id: 211,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 212
            }]
        }, {
            id: 212,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 213
            }]
        }, {
            id: 213,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'bad news bears...',
                path: 214
            }, {
                cta: 'ughh... so bad!! you?',
                path: 215
            }, {
                cta: ':\'(',
                path: 216
            }]
        }, {
            id: 214,
            points: 0,
            imgurl: "assets/img/ellen/ellenbadnewsbears.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 215,
            points: 0,
            imgurl: "assets/img/ellen/ellenughhsobad.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 216,
            points: 0,
            imgurl: "assets/img/ellen/ellencryingemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 48,
            points: 0,
            imgurl: "assets/img/ellen/ellen50a.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 49
            }, {
                cta: 'Keep texting.',
                path: 121
            }]
        }, {
            id: 49,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_dohw.png",
            type: '',
            paths: [{
                cta: null,
                path: 50
            }]
        }, {
            id: 50,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_homeworktime.png",
            type: 'end',
            paths: []
        }, {
            id: 121,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_texttom.png",
            type: 'end2',
            paths: []
        }]
    });
});