define('digital-style/models/spaghetti/c1', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p00.png",
            type: 'start',
            paths: [{
                cta: null,
                path: '1'
            }]
        }, {
            id: '1',
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p01.png",
            type: '',
            paths: [{
                cta: null,
                path: '1-diag'
            }]
        }, {
            id: '1-diag',
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p01.5.png",
            type: '',
            paths: [{
                cta: '*Fistbump*',
                path: '2-bump'
            }, {
                cta: '*High Five*',
                path: '2-hf'
            }]
        }, {
            id: '2-bump',
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p02.2.png",
            type: '',
            paths: [{
                cta: null,
                path: '2-spaghetti'
            }]
        }, {
            id: '2-hf',
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p02.1.png",
            type: '',
            paths: [{
                cta: null,
                path: '2-spaghetti'
            }]
        }, {
            id: '2-spaghetti',
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p02.png",
            type: '',
            paths: [{
                cta: null,
                path: '3-spaghetti'
            }]
        }, {
            id: '3-spaghetti',
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p03.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p04.gif",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p05.gif",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p06d.gif",
            type: '',
            paths: [{
                cta: '[Inspect Dish]',
                path: 7
            }, {
                cta: '[Inspect Plaque]',
                path: 80
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p07.png",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p08.png",
            type: '',
            paths: [{
                cta: null,
                path: "9-plate"
            }]
        }, {
            id: 80,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p08.png",
            type: '',
            paths: [{
                cta: null,
                path: 70
            }]
        }, {
            id: 70,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p07.png",
            type: '',
            paths: [{
                cta: null,
                path: "9-plate"
            }]
        }, {
            id: "9-plate",
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p10.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p11.png",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p12.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p14.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p15.png",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/spaghetti/c1/treasure_c1p16.png",
            type: 'end',
            paths: []
        }]
    });
});