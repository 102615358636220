define('digital-style/models/ellen/04-reject-homework', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_4_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 101
            }]
        }, {
            id: 101,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 102
            }]
        }, {
            id: 102,
            points: 0,
            imgurl: "assets/img/ellen/ellen53_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 103
            }]
        }, {
            id: 103,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 104
            }]
        }, {
            id: 104,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 105
            }]
        }, {
            id: 105,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 106
            }]
        }, {
            id: 106,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 289
            }]
        }, {
            id: 289,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Text later",
                path: 290
            }, {
                cta: "Continue Texting",
                path: 107
            }]
        }, {
            id: 290,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 291
            }]
        }, {
            id: 291,
            points: 0,
            imgurl: "assets/img/ellen/ellen81.png",
            type: '',
            paths: [{
                cta: null,
                path: 292
            }]
        }, {
            id: 292,
            points: 0,
            imgurl: "assets/img/ellen/ellen82.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 293
            }]
        }, {
            id: 293,
            points: 0,
            imgurl: "assets/img/ellen/ellen83cathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 294
            }]
        }, {
            id: 294,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 295
            }]
        }, {
            id: 295,
            points: 0,
            imgurl: "assets/img/ellen/ellen85.png",
            type: '',
            paths: [{
                cta: null,
                path: 296
            }]
        }, {
            id: 296,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 297
            }]
        }, {
            id: 297,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 298
            }]
        }, {
            id: 298,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 299
            }]
        }, {
            id: 299,
            points: 0,
            imgurl: "assets/img/ellen/ellen76thankcathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 300
            }]
        }, {
            id: 300,
            points: 0,
            imgurl: "assets/img/ellen/ellen77thankcathy.png",
            type: 'end',
            paths: []
        }, {
            id: 107,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 108
            }]
        }, {
            id: 108,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 109
            }]
        }, {
            id: 109,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 110
            }]
        }, {
            id: 110,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 111
            }]
        }, {
            id: 111,
            points: 0,
            imgurl: "assets/img/ellen/ellen68_spazz.png",
            type: '',
            paths: [{
                cta: null,
                path: 113
            }]
        },
        // {
        //     id: 112,
        //     points: 0,
        //     imgurl: "assets/img/ellen/ellen69.png",
        //     type: '',
        //     paths: [
        //         {
        //             cta: null,
        //             path: 113
        //         }
        //     ]
        // },
        {
            id: 113,
            points: 0,
            imgurl: "assets/img/ellen/ellen70_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 114
            }]
        }, {
            id: 114,
            points: 0,
            imgurl: "assets/img/ellen/ellen71_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 117
            }]
        },
        /*
                {
                    id: 115,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 116
                        }
                    ]
                },
                {
                    id: 116,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 117
                        }
                    ]
                },
        */
        {
            id: 117,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 118
            }]
        }, {
            id: 118,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 119
            }]
        }, {
            id: 119,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 120
            }]
        }, {
            id: 120,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 250
            }]
        }, {
            id: 250,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu1.png",
            type: '',
            paths: [{
                cta: null,
                path: 251
            }]
        }, {
            id: 251,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu2.png",
            type: '',
            paths: [{
                cta: null,
                path: 252
            }]
        }, {
            id: 252,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 253
            }]
        }, {
            id: 253,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 254
            }]
        }, {
            id: 254,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }]
    });
});