define('digital-style/models/darby/c2-pc', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        housecraftclue: _emberData['default'].attr('string'),
        housecraftdownloaded: _emberData['default'].attr('string'),
        housecraftopened: _emberData['default'].attr('string'),
        blogclue: _emberData['default'].attr('string'),
        antivirusclue: _emberData['default'].attr('string'),
        antivirusdownloaded: _emberData['default'].attr('string'),
        antivirusopened: _emberData['default'].attr('string'),
        junkfolderclue: _emberData['default'].attr('string'),
        translatepanelsopened: _emberData['default'].attr('string'),
        translateunlocked: _emberData['default'].attr('string'),
        translatedone: _emberData['default'].attr('string')

    }).reopenClass({
        FIXTURES: [{
            id: 1,
            //housecraft
            housecraftclue: 'false',
            //post-downloaded housecraft
            housecraftdownloaded: 'false',
            //opened housecraft
            housecraftopened: 'false',
            //blog
            blogclue: 'false',
            //antivirus
            antivirusclue: 'false',
            //post-downloaded antivirus
            antivirusdownloaded: 'false',
            //opened antivirus
            antivirusopened: 'false',
            //junk folder
            junkfolderclue: 'false',
            //Pre Translate Panels OPened?
            translatepanelsopened: 'false',
            //Is translate notepad clue "hex code" allowed to show?
            translateunlocked: 'false',
            //Has the user translated the string?
            translatedone: 'false'
        }]
    });
});