define('digital-style/models/spaghetti/c3', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p00.png",
            type: 'start',
            paths: [{
                cta: null,
                path: '1'
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p01.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 110
            }]
        }, {
            id: 110,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p01a.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p02.png",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p03.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p04.png",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p05.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p06.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p07.png",
            type: '',
            paths: [{
                cta: null,
                path: '8-water'
            }]
        }, {
            id: '8-water',
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p08.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p10.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p11.png",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p12.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p14.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p15.png",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p16.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p17.png",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p18.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p19.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p20.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p21.png",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/spaghetti/c3/treasure_c3p22.png",
            type: 'end',
            paths: []
        }]
    });
});

// {
//     id: 23,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p23.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 24
//         }
//     ]
// },
// {
//     id: 24,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p24.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 25
//         }
//     ]
// },
// {
//     id: 25,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p25.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 26
//         }
//     ]
// },
// {
//     id: 26,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p26.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 27
//         }
//     ]
// },
// {
//     id: 27,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p27.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 28
//         }
//     ]
// },
// {
//     id: 28,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p28.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 29
//         }
//     ]
// },
// {
//     id: 29,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p29.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 30
//         }
//     ]
// },
// {
//     id: 30,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p30.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 31
//         }
//     ]
// },
// {
//     id: 31,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p31.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 32
//         }
//     ]
// },
// {
//     id: 32,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p32.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 33
//         }
//     ]
// },
// {
//     id: 33,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p33.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 34
//         }
//     ]
// },
// {
//     id: 34,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p34.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 35
//         }
//     ]
// },
// {
//     id: 35,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c3/treasure_c3p35.png",
//     type: 'end',
//     paths: [

//     ]
// },