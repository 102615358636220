define('digital-style/models/darby/c2-junk', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 1,
            points: 0,
            imgurl: "assets/img/darby-c2/junk/c2junkp1.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby-c2/junk/c2junkp2.png",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby-c2/junk/c2junkp3.png",
            type: 'end',
            paths: []
        }]
    });
});