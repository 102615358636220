define('digital-style/controllers/comic/darby/c2-junk', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({
        needs: ['application']
    });
});
/*
    actions: {
        addPanel: function (nextPanelId, time) {
            //console.log('story addPanel');
            //console.log(nextPanelId);

            // set this; get the character selected in the application's controller
            var t = this,
                idx,
                opts,
                img,
                imgName,
                panelcls,
                intx,
                times = (!!time) ? (parseInt(time, 10) + 1) : 1,
                isSecondTime = times === 2;

			document.body.style.background = '#2e343e';


            // find the the store that matches the character selected, then...
            this.store.find('darby/c2', nextPanelId).then(function (arr) {
                idx = arr.get('id');
                opts = arr.get('paths');
                img = arr.get('imgurl');
                imgName = img.split('/').pop().split('.');
                imgName.splice(-1, 1);
                panelcls = imgName.join('').split('-')[0];

                // add new panel to the comic model
                t.store.createRecord('comic', {
                    id: idx,
                    type: arr.get('type'),
                    imgurl: arr.get('imgurl'),
                    cls: panelcls,
                    paths: opts
                }).save();

                setTimeout(function () {
                    // load next panel if there's only one path
                    if (opts.length === 1) {
                        // hide the paths element
                        $('#panel-' + idx).find('.paths').hide();

                        // send action to add next panel
                        t.send('addPanel', arr.get('paths')[0]['path'], times);

                        if (isSecondTime) {
                            // flash scroll notification
                            intx = setInterval(function () {
                                $('#comic > .scroll-notify').fadeToggle(250);
                            }, 500);

                            // stop flashing
                            setTimeout(function () {
                                clearInterval(intx);
                            }, 3500);
                        }
                    } else if (opts.length === 0) {
                        // hide the paths element
                        $('#panel-' + idx).find('.paths').hide();
                    }

                    if (arr.get('type') === 'end') {
                        $('#story #retrospective').show();
                    }
                }, 100);
            });
        }

    }
*/