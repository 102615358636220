define('digital-style/models/darby/c1', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/darby/chap1_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/darby/c1p1.gif",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby/c1p2.gif",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby/c1p3.gif",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/darby/c1p4d.gif",
            type: '',
            paths: [{
                cta: 'Okay, just a piece.',
                path: 5
            }, {
                cta: 'Have all of it',
                path: 18
            }, {
                cta: 'Get your own!',
                path: 19
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/darby/c1p5.gif",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/darby/c1p18.gif",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/darby/c1p19.gif",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/darby/c1p6.gif",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/darby/c1p7.gif",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        },
        //Future Planning Path ID Number (when the three alternates are in
        {
            id: 8,
            points: 0,
            imgurl: "assets/img/darby/c1p8.gif",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/darby/c1p9.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/darby/c1p10.gif",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/darby/c1p11.gif",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/darby/c1p12.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/darby/c1p13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/darby/c1p14.gif",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/darby/c1p15.gif",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/darby/c1p16.gif",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/darby/c1p17.png",
            type: 'end',
            paths: []
        }]
    });
});