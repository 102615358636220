define("digital-style/routes/site/darby-chp", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {
                //console.log('intro-1 done');

                // wait a little for DOM to be really ready
                setTimeout(function () {
                    _ember["default"].$("body").addClass("mainSiteBg");
                    _ember["default"].$(".module-title").delay(500).animate({ opacity: 1 }, 700);
                }, 50);
            },
            // run this before transition happens
            willTransition: function willTransition() {
                _ember["default"].$("body").removeClass("mainSiteBg");
            }
        }
    });
});