define('digital-style/models/spaghetti', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        recipeCalculator: _emberData['default'].attr('number'),
        currentPage: _emberData['default'].attr('number'),
        spaghetti: _emberData['default'].attr('boolean'),
        plate: _emberData['default'].attr('boolean'),
        fruits: _emberData['default'].attr('boolean'),
        grains: _emberData['default'].attr('boolean'),
        dairy: _emberData['default'].attr('boolean'),
        protein: _emberData['default'].attr('boolean'),
        water: _emberData['default'].attr('boolean')

    }).reopenClass({
        FIXTURES: [{

            id: 1,
            recipeCalculator: 0,
            currentPage: 'spaghetti',
            //
            spaghetti: 'false',
            plate: 'false',
            fruits: 'false',
            grains: 'false',
            dairy: 'false',
            protein: 'false',
            water: 'false'

        }]
    });
});