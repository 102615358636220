define('digital-style/controllers/intro/intro-2', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        // content for intro.intro-2 route
        preload_1: 'assets/img/lab.png',
        preload_2: 'assets/img/screenfilter_withbackground.gif',
        preload_3: 'assets/img/retro/patuscreen_large.gif',
        img_1: 'assets/img/patu_01a.png',
        copy_1: 'My name is Patoo.',
        copy_2: 'Nice to meet you!',
        cta_1: 'Likewise.'
    });
});