define('digital-style/routes/comic/darby/c1', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		controllerName: 'comic/story',
		// comic model to build the comic
		model: function model() {
			return this.store.find('comic');
		},
		actions: {
			// run this once the comic route is loaded
			didTransition: function didTransition() {
				var t = this;

				// show scroll instructions
				setTimeout(function () {

					// fix size
					$('body').css({ 'width': window.innerWidth + 'px' });

					t.controller.set('modelToUse', 'darby/c1');

					// clear all previous comic panels
					t.store.unloadAll('comic');

					// add the first panel of the model
					t.get('controller').send('addPanel', 0);

					document.body.style.background = '#2e343e';

					// remove scroll lock
					$('#comic').removeClass('lock');

					// wait for DOM to be ready
					$(document).ready(function () {
						//                     ts.init();

						var notified = false;
						var documentHeightAtFirstChoice;
						$(window).scroll(function () {
							setTimeout(function () {
								t.get('controller').send('topBannerScrollHandler');
							}, 2000);

							if (notified === false) {
								var documentHeight = $(document).height() - 300;

								if ($(window).scrollTop() + $(window).height() >= documentHeight) {
									notified = true;
									documentHeightAtFirstChoice = $(document).height();
									console.log(documentHeightAtFirstChoice);

									_ember['default'].$("#choiceInstructions").fadeIn(1000);
								}
							}

							//once the notification shows up and the users clicks, this will make the banner hide again
							if (notified === true) {
								if ($(document).height() >= documentHeightAtFirstChoice + 150) {
									$("#choiceInstructions").fadeOut(1000);
								}
							}
						});
					});
				}, 100);
			}
		}
	});
});
/*globals $ */