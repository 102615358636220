define("digital-style/templates/intro/intro-4", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "intro-4");
        dom.setAttribute(el1, "class", "css3 slow on");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "characters");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "id", "copy-1");
        dom.setAttribute(el3, "class", "silkscreen font48");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "id", "darby");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        dom.setAttribute(el5, "class", "option css3 faster");
        var el6 = dom.createElement("img");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "id", "ellen");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        dom.setAttribute(el5, "class", "option css3 faster");
        var el6 = dom.createElement("img");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "id", "cta-1");
        dom.setAttribute(el3, "class", "silkscreen font48");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks,
            content = hooks.content,
            element = hooks.element,
            get = hooks.get,
            concat = hooks.concat,
            attribute = hooks.attribute;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element2, [0]);
        var element4 = dom.childAt(element1, [3, 1]);
        var element5 = dom.childAt(element4, [0]);
        var morph0 = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        var attrMorph0 = dom.createAttrMorph(element3, 'src');
        var attrMorph1 = dom.createAttrMorph(element3, 'alt');
        var attrMorph2 = dom.createAttrMorph(element5, 'src');
        var attrMorph3 = dom.createAttrMorph(element5, 'alt');
        var morph1 = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
        content(env, morph0, context, "copy_1");
        element(env, element2, context, "action", ["characterSelected", "darby"], {});
        attribute(env, attrMorph0, element3, "src", concat(env, [get(env, context, "char_1_img")]));
        attribute(env, attrMorph1, element3, "alt", concat(env, [get(env, context, "char_1")]));
        element(env, element4, context, "action", ["characterSelected", "ellen"], {});
        attribute(env, attrMorph2, element5, "src", concat(env, [get(env, context, "char_2_img")]));
        attribute(env, attrMorph3, element5, "alt", concat(env, [get(env, context, "char_2")]));
        content(env, morph1, context, "cta_1");
        return fragment;
      }
    };
  })());
});