define('digital-style/controllers/comic/story', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({
        needs: ['application'],
        sky1: 'assets/img/bg/sky1_2.png',
        sky2: 'assets/img/bg/sky2.png',
        stars: 'assets/img/bg/stars_2.png',
        moon: 'assets/img/bg/moon_2.png',
        house: 'assets/img/bg/house_2.png',
        ending: '',
        scrollInstructions: false,
        lp_goodbadsleep: false,
        lp_artgameshang: false,

        /*
            charId: function () {
                return this.get('controllers.application').get('character');
            }.property('controllers.application.character'),
        */
        modelToUse: '',
        actions: {
            addPanel: function addPanel(nextPanelId, time) {

                //Stop audio, in case it is still playing.. piggy backs off the add panel Functionality
                // if (this.get('controllers.application.audio_paused')  === false ){
                //     // $('.bgmusic').each(function(){
                //     //     var t2 = $(this);
                //     //     $(this).animate({volume: 0}, 2000, 'swing', function() {
                //     //         // really stop the music
                //     //         t2[0].pause(); // Stop playing
                //     //         t2.currentTime = 0; // Reset time
                //     //         // t.controllerFor('application').set('audio_paused', true);
                //     //         t.set('controllers.application.audio_paused', true);
                //     //
                //     //     });
                //     //
                //     // });
                //     $('#rain').get(0).pause();
                //     $('#rain').get(0).currentTime = 0;
                //     this.set('controllers.application.audio_paused', true);
                // }

                //console.log('story addPanel');
                //console.log(nextPanelId);

                // set this; get the character selected in the application's controller
                var t = this,

                //                 c = t.get('charId'),
                c = t.get('modelToUse'),
                    idx,
                    opts,
                    img,
                    imgName,
                    panelcls,
                    intx,
                    times = !!time ? parseInt(time, 10) + 1 : 1,
                    isSecondTime = times === 2;

                // find the the store that matches the character selected, then...
                this.store.find(c, nextPanelId).then(function (arr) {
                    idx = arr.get('id');
                    opts = arr.get('paths');
                    img = arr.get('imgurl');
                    imgName = img.split('/').pop().split('.');
                    imgName.splice(-1, 1);
                    panelcls = imgName.join('').split('-')[0];

                    // add new panel to the comic model
                    t.store.createRecord('comic', {
                        id: idx,
                        type: arr.get('type'),
                        imgurl: arr.get('imgurl'),
                        cls: panelcls,
                        paths: opts
                    }).save();

                    setTimeout(function () {
                        // load next panel if there's only one path
                        if (opts.length === 1) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();

                            // send action to add next panel
                            t.send('addPanel', arr.get('paths')[0]['path'], times);

                            if (isSecondTime) {
                                // flash scroll notification
                                intx = setInterval(function () {
                                    $('#comic > .scroll-notify').fadeToggle(250);
                                }, 500);

                                // stop flashing
                                setTimeout(function () {
                                    clearInterval(intx);
                                }, 3500);
                            }
                        } else if (opts.length === 0) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();
                        }

                        if (arr.get('type') === 'end') {
                            $('#story #retrospective').show();
                        }
                        if (arr.get('type') === 'end2') {
                            $('#story #retrospective2').show();
                        }
                        if (arr.get('type') === 'end3') {
                            t.set('ending', '1');
                        }
                        if (arr.get('type') === 'end4') {
                            t.set('ending', '2');
                        }
                        if (arr.get('type') === 'end5') {
                            var end = t.get('ending');
                            if (end === '1') {
                                $('#story #retrospective').show();
                            }
                            if (end === '2') {
                                $('#story #retrospective2').show();
                            }
                        }
                        if (t.get('scrollInstructions') === false) {
                            _ember['default'].$("#topPasswordOrInstructions").animate({ top: "+=200" }, 1000);
                            t.set('scrollInstructions', true);
                        }
                    }, 100);
                });
            },
            hideLearningPanel: function hideLearningPanel(id) {
                document.getElementById(id).style.display = 'none';
                _ember['default'].$(".learning-point-overlay").fadeOut(400);
            },
            showLearningPanel: function showLearningPanel(id) {
                document.getElementById(id).style.display = 'inline';
                _ember['default'].$("#" + id + "Banner").hide();
                _ember['default'].$(".learning-point-overlay").fadeIn(400);
            },
            // isScrolledIntoView:function(trigger, elem){

            topBannerScrollHandler: function topBannerScrollHandler() {
                if (_ember['default'].$(window).scrollTop() > 500) {
                    _ember['default'].$("#topPasswordOrInstructions").fadeOut();
                }
            },

            recipeHandler: function recipeHandler() {
                this.store.find('spaghetti', 1).then(function (arr) {
                    //Dev mode to set all the things if necessary
                    console.log("here");
                    arr.set('recipeCalculator', 2);
                });
            },

            spaghettiJournalScrollHandler: function spaghettiJournalScrollHandler() {
                // var continueTextLP = ['#panel-62', '#panel-107', '#panel-134', '#panel-157'];
                // var textLaterLP = ['#panel-269', '#panel-279', '#panel-290', '#panel-302'];
                if ($("#story").hasClass("spaghetti-c1")) {
                    // var store = this.get('targetObject.store');
                    if ($('#panel-6').length === 1) {
                        this.store.find('spaghetti', 1).then(function (arr) {
                            _ember['default'].$("#thatsBaadLearningPoint").fadeIn(1000);
                        });
                    }
                }
                // if ($( "#story" ).hasClass("spaghetti-c1") && this.get('lp_goodbadsleep') === false){
                //     if ($(continueTextLP[i]).length === 1){

                //     for (var i = 0; i < continueTextLP.length; i++) {
                //         if ($(continueTextLP[i]).length === 1){
                //             // Ember.$("#thatsBaadLPBanner").fadeIn(1000);

                //             this.set('lp_goodbadsleep', true);
                //         }

                //         if ($(textLaterLP[i]).length === 1){
                //             Ember.$("#thatsGoodLP").fadeIn(1000);
                //             this.set('lp_goodbadsleep', true);
                //         }
                //     }
                // }
            },

            ellenLearningPointsScrollHandler: function ellenLearningPointsScrollHandler() {
                // console.log('scrollHandler');

                var continueTextLP = ['#panel-62', '#panel-107', '#panel-134', '#panel-157'];
                var textLaterLP = ['#panel-269', '#panel-279', '#panel-290', '#panel-302'];

                // if ($(elem).length === 1){
                //
                //     var docViewTop = $(window).scrollTop();
                //     var docViewBottom = docViewTop + $(window).height();
                //     var elemTop = $(elem).offset().top;
                //     var elemBottom = elemTop + $(elem).height();
                //     if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop)){
                //
                //         if (trigger === 'ellen' && elem.selector === ('#panel-62' || '#panel-107' || '#panel-134' || '#panel-157')){
                //             Ember.$("#thatsBaadLearningPoint").fadeIn(1000);
                //         }
                //
                //
                //     }
                // }
                if ($("#story").hasClass("ellen") && $("#story").hasClass("c4") && this.get('lp_goodbadsleep') === false) {
                    for (var i = 0; i < continueTextLP.length; i++) {
                        if ($(continueTextLP[i]).length === 1) {
                            _ember['default'].$("#thatsBaadLPBanner").fadeIn(1000);
                            this.set('lp_goodbadsleep', true);
                        }

                        if ($(textLaterLP[i]).length === 1) {
                            _ember['default'].$("#thatsGoodLP").fadeIn(1000);
                            this.set('lp_goodbadsleep', true);
                        }
                    }

                    // for (var i = 0; i < textLaterLP.length; i++) {
                    //
                    // }
                }

                var artBad = ['#panel-1107', '#panel-1109'];
                var artGood = ['#panel-1108', '#panel-1110', '#panel-1112'];

                var vgBad = ['#panel-1206', '#panel-1207'];
                var vgGood = ['#panel-1205'];

                var hangBad = ['#panel-1304', '#panel-1305'];
                var hangGood = ['#panel-1305', '#panel-1306'];

                // var goodSleepLP = ['#panel-1108', '#panel-1110', '#panel-1112', '#panel-1205', '#panel-1305', '#panel-1306' ];
                // var badSleepLP = ['#panel-1107', '#panel-1109', '#panel-1206', '#panel-1207', '#panel-1304', '#panel-1305'];

                if ($("#story").hasClass("ellen") && $("#story").hasClass("c2-bad") && this.get('lp_artgameshang') === false) {

                    //Same length for all of them
                    for (var j = 0; j < artBad.length; j++) {
                        if ($(artBad[j]).length === 1) {
                            _ember['default'].$("#artLPBanner").fadeIn(1000);
                            this.set('lp_artgameshang', true);
                        }
                    }
                    for (var k = 0; k < vgBad.length; k++) {
                        if ($(vgBad[k]).length === 1) {
                            _ember['default'].$("#videoGamesLPBanner").fadeIn(1000);
                            this.set('lp_artgameshang', true);
                        }
                    }

                    for (var l = 0; l < hangBad.length; l++) {
                        if ($(hangBad[l]).length === 1) {
                            _ember['default'].$("#hangoutLPBanner").fadeIn(1000);
                            this.set('lp_artgameshang', true);
                        }
                    }
                }

                if ($("#story").hasClass("ellen") && $("#story").hasClass("c2-good") && this.get('lp_artgameshang') === false) {

                    for (var m = 0; m < artGood.length; m++) {
                        if ($(artGood[m]).length === 1) {
                            _ember['default'].$("#artLPBanner").fadeIn(1000);
                            this.set('lp_artgameshang', true);
                        }
                    }
                    for (var n = 0; n < vgGood.length; n++) {
                        if ($(vgGood[n]).length === 1) {
                            _ember['default'].$("#videoGamesLPBanner").fadeIn(1000);
                            this.set('lp_artgameshang', true);
                        }
                    }
                    for (var q = 0; q < hangGood.length; q++) {
                        if ($(hangGood[q]).length === 1) {
                            _ember['default'].$("#hangoutLPBanner").fadeIn(1000);
                            this.set('lp_artgameshang', true);
                        }
                    }
                }
            }
        }

    });
});
/*globals $ */