define('digital-style/routes/comic/darby/c2-internets-housecraft', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		// comic model to build the comic
		model: function model() {
			return this.store.find('darby/c2-pc');
		},
		actions: {
			// run this once the comic route is loaded

			didTransition: function didTransition() {
				setTimeout(function () {
					_ember['default'].$('#comic').removeClass('lock');
					window.scrollTo(0, 0);
				}, 100);
			},

			downloadHousecraft: function downloadHousecraft() {
				this.store.find('darby/c2-pc', 1).then(function (arr) {
					arr.set('housecraftclue', true);
				});
			}
		}
	});
});