define('digital-style/components/scroll-notification', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        didInsertElement: function didInsertElement() {
            /*
                    $('#scroll-notify').on('click', function () {
                        $(this).fadeOut(250);
                    });
            */
        }
    });
});