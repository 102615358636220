define('digital-style/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        // content for application route - main controller
        logo: 'assets/img/logo.png',
        ubc_logo: 'assets/img/ubc.png',
        appName: 'Adventures of Patoo',
        loggedIn: false,
        lastRoute: 'site.main',
        pass: 'patoo',
        year: new Date().getFullYear().toString(),
        character: null,
        userName: null,
        img: 'assets/img/device_support.png',
        preload_1: 'assets/img/lab.png',
        preload_2: 'assets/img/screenfilter_withbackground.gif',
        preload_3: 'assets/img/retro/patuscreen_large.gif',
        // audio_paused: false,
        //Save State Across Ellen Interface

        // actions for application controller - first login
        actions: {
            login: function login() {
                //console.log('login');

                // get the answer from user's input
                var answer = this.get('answer').toLowerCase();

                // call action to check if the answer is valid
                this.send('check', answer);
            },
            check: function check(answer) {
                //console.log('check');

                // get the pass from the content above
                var pass = this.get('pass');
                //             this.send('success', answer);

                /*
                            // check answer vs pass
                            if (answer === pass) {
                                this.send('success', answer);
                            } else {
                                this.send('failure');
                            }
                */
                console.log(answer);
                if (answer === 'sushi') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c1');
                } else if (answer === 'darby') {
                    this.send('success');

                    this.transitionToRoute('comic.darby.c2');
                } else if (answer === 'pc') {
                    this.send('success');

                    this.transitionToRoute('comic.darby.c2-pc');
                } else if (answer === 'suit') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c3');
                } else if (answer === 'diamond') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c4-shield');
                } else if (answer === 'shield') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c5-shield');
                } else if (answer === 'foobar') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c4-blaster');
                } else if (answer === 'blaster') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c5-blaster');
                } else if (answer === 'shield') {
                    this.send('success');
                    this.transitionToRoute('comic.darby.c5-shield');
                } else if (answer === pass) {
                    this.send('success');
                    this.transitionToRoute('intro.intro-1');
                } else {
                    this.send('failure');
                }
            },
            success: function success() {
                //console.log('success');

                var t = this;
                // var pass = this.get('pass');

                // set input's field class to success and remove the error class
                $('#answer').removeClass('error').addClass('success');

                // wait very little to toggle the app's class to on
                setTimeout(function () {
                    $('#app').toggleClass('on off');
                    t.set('loggedIn', true);
                }, 100);

                // wait 1 second to transition to next route so out-animations can happen

                /*
                            setTimeout(function () {
                                // set the above loggedIn to true which will change the current view
                                t.set('loggedIn', true);
                                if (answer === 'darby'){
                	                t.transitionToRoute('comic.darby.c1')
                                }
                                else if (answer === 'pc'){
                	                t.transitionToRoute('comic.darby.c2-pc');
                                }
                                else if (answer === pass){
                	                // transition to the introduction
                	                t.transitionToRoute('intro.intro-1');
                                }
                
                
                
                            }, 1e3);
                */
            },
            failure: function failure() {
                //console.log('failure');

                // set the above loggedIn to false
                this.set('loggedIn', false);

                // set input's field class to error and remove the success class
                _ember['default'].$('#answer').removeClass('success').addClass('error');
            },
            updateState: function updateState() {
                // remove both success and error classes when user starts typing
                _ember['default'].$('#answer').removeClass('success error');
            },
            showCredits: function showCredits() {
                _ember['default'].$('#credits').show();
            },

            goBack: function goBack() {
                this.transitionTo(this.get('lastRoute'));
            }
        }
    });
});
/*globals $ */