define('digital-style/routes/comic/darby/c2-internets-translate', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		model: function model() {
			return this.store.find('darby/c2-pc');
		},
		actions: {
			// run this once the comic route is loaded

			didTransition: function didTransition() {
				var t = this;
				setTimeout(function () {
					// 	    	this.get('controller').send('initStore');
					window.scrollTo(0, 0);
					_ember['default'].$('#comic').addClass('lock');
					t.store.find('darby/c2-pc', 1).then(function (arr) {
						// 					arr.set('translateunlocked', true);
						var unlocked = arr.get('translateunlocked');
						if (unlocked !== 'false') {
							_ember['default'].$("#hintCodeSheet").delay(100).animate({ bottom: "+=720" }, 1000, function () {
								_ember['default'].$('#comic').removeClass('lock');
							});
							// Ember.$("#instructionsBanner").delay(100).animate({top: "+=200"}, 1000);
						}
					});
				}, 100);
			}

		}
	});
});