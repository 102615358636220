define("digital-style/routes/site/profile/darby", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {

                // wait a little for DOM to be really ready
                setTimeout(function () {
                    _ember["default"].$("body").addClass("mainSiteBg");
                }, 250);
            },
            // run this before transition happens
            willTransition: function willTransition() {
                _ember["default"].$("body").removeClass("mainSiteBg");
                // t.controllerFor('application').set('lastRoute', t.routeName);
            },
            goBack: function goBack() {
                var routeToGo = this.controllerFor('application').get('lastRoute');
                this.transitionTo(routeToGo);
            }
        }
    });
});