define('digital-style/models/ellen/01-prologue', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 'first',
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight_2.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/ellen/ellen01.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue.png",
            type: '',
            paths: [{
                cta: 'Bring it!',
                path: 3
            }, {
                cta: 'Not really...',
                path: 4
            }, {
                cta: 'Hang on.',
                path: 5
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue_a.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue_b.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue_c.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/ellen/ellen02.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/ellen/ellen03.png",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/ellen/ellen04.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/ellen/ellen05.gif",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/ellen/ellen06.png",
            type: '',
            paths: [{
                cta: 'Go to bed.',
                path: 11
            }, {
                cta: 'Study longer.',
                path: 12
            }, {
                cta: 'Listen to music.',
                path: 13
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/ellen/ellen08.png",
            type: 'end',
            paths: []
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/ellen/ellen07alternate.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/ellen/ellen07.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/ellen/ellen08.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/ellen/ellen09.png",
            type: 'end2',
            paths: []
        }]
    });
});