define('digital-style/controllers/comic/darby/c2-internets-translate', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		needs: ['application'],
		actions: {
			translateHex: function translateHex() {
				var t = this;
				// 	        var answer = this.get('value');
				var answer = document.getElementById('HexField').value;
				var str = '';
				for (var i = 0; i < answer.length; i += 3) {
					var v = parseInt(answer.substr(i, 2), 16);
					if (v) {
						str += String.fromCharCode(v);
					}
				}
				document.getElementById('StringField').value = str;
				// 		    return str;
				if (str === "I'm feeling sad") {
					t.store.find('darby/c2-pc', 1).then(function (arr) {
						setTimeout(function () {
							arr.set('translatedone', 'true');
							_ember['default'].$("#finishedBanner").delay(100).animate({ top: "+=200" }, 1000);
							_ember['default'].$(".darbyBtns").prop("disabled", true);
							_ember['default'].$(".DarbyUIButton").prop("disabled", true);
						}, 100);
					});
				}
			},
			autoCP: function autoCP() {
				document.getElementById('HexField').value = "49 27 6d 20 66 65 65 6c 69 6e 67 20 73 61 64";
			}

		}

	});
});