define('digital-style/routes/intro/intro-3', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {
                //console.log('intro-3 done');

                // wait a little for DOM to be really ready
                setTimeout(function () {
                    $('#intro-3').toggleClass('on off');
                }, 100);
            }
        }
    });
});
/*globals $ */