define('digital-style/controllers/comic/darby/c2-pc', ['exports', 'ember'], function (exports, _ember) {
	var $ = _ember['default'].$;
	exports['default'] = _ember['default'].ArrayController.extend({
		needs: ['application'],
		darbybg: 'assets/img/darby-c2/darby-bg.png',
		blankWindow: 'assets/img/darby-c2/blank-window.png',
		charId: 'darby-pc',
		actions: {

			openWindow: function openWindow(targetId) {
				document.getElementById(targetId).style.display = 'inline';
			},

			randomJpgBtn: function randomJpgBtn() {

				document.getElementById("randomJpgWindow").style.display = 'inline';

				this.store.find('darby/c2-pc', 1).then(function (arr) {
					var visitedBefore = arr.get('junkfolderclue');
					console.log(visitedBefore);
					if (visitedBefore === 'false') {
						arr.set('junkfolderclue', 'true');

						document.getElementById("notificationBanner").style.display = 'inline';

						// 					$('#how-to').delay(1000).fadeIn(1000);
						$("#notificationBanner").delay(1600).animate({ top: "+=200" }, 2000);

						$(".darbyBtns").prop("disabled", true);
						$(".DarbyUIButton").prop("disabled", true);
					} else {
						//sets the overlay again, but currently doesn't know how to hide it
						// 					$('#how-to').css("z-index", "99");
						// 					$('#how-to').delay(1000).fadeIn(1000);
					}
				});
			},

			antivirusBtn: function antivirusBtn() {

				this.store.find('darby/c2-pc', 1).then(function (arr) {
					var visitedBefore = arr.get('antivirusopened');
					console.log(visitedBefore);
					if (visitedBefore === 'false') {
						arr.set('antivirusopened', 'true');

						document.getElementById("antivirusBanner").style.display = 'inline';

						// 					$('#how-to').delay(1000).fadeIn(1000);
						_ember['default'].$("#antivirusBanner").delay(100).animate({ top: "+=200" }, 2000);

						_ember['default'].$(".darbyBtns").prop("disabled", true);
						_ember['default'].$(".DarbyUIButton").prop("disabled", true);
					} else {
						//sets the overlay again, but currently doesn't know how to hide it
						// 					$('#how-to').css("z-index", "99");
						// 					$('#how-to').delay(1000).fadeIn(1000);
					}
				});
			},

			housecraftError: function housecraftError() {
				this.send('openWindow', 'housecraftError');

				this.store.find('darby/c2-pc', 1).then(function (arr) {
					var visitedBefore = arr.get('housecraftopened');
					// 			    var antivirusUnlocked = arr.get('');
					console.log(visitedBefore);
					if (visitedBefore === 'false') {
						arr.set('housecraftopened', 'true');

						document.getElementById("housecraftBanner").style.display = 'inline';

						// 					$('#how-to').delay(1000).fadeIn(1000);
						_ember['default'].$("#housecraftBanner").delay(1600).animate({ top: "+=145" }, 2000);

						_ember['default'].$(".darbyBtns").prop("disabled", true);
						_ember['default'].$(".DarbyUIButton").prop("disabled", true);
					} else {
						//sets the overlay again, but currently doesn't know how to hide it
						// 					$('#how-to').css("z-index", "99");
						// 					$('#how-to').delay(1000).fadeIn(1000);
					}
				});
			},

			openInternets: function openInternets() {
				this.transitionToRoute('comic.darby.c2-internets-robogle');
			}

		}
	});
});