define('digital-style/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {

    /*
    export default DS.RESTAdapter.extend({
    });
    */

    // using fixtures adapter for now
    exports['default'] = _emberData['default'].FixtureAdapter.extend({
        queryFixtures: function queryFixtures(records, query /*, type */) {
            return records.filter(function (record) {
                for (var key in query) {
                    if (!query.hasOwnProperty(key)) {
                        continue;
                    }
                    var value = query[key];
                    if (record[key] !== value) {
                        return false;
                    }
                }
                return true;
            });
        }
    });

    /*
    export default DS.LSAdapter.extend({
    });
    */
});