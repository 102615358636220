define('digital-style/models/darby/c4-shield', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield00.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield01.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield02.gif",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield03.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield04.gif",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield05.gif",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield06.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield07.gif",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield08.gif",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield09.gif",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield10.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield11d.png",
            type: '',
            paths: [{
                cta: "Chill out, Patoo",
                path: 12
            }, {
                cta: "What's up, birthday dude?",
                path: 13
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield12.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield13d.png",
            type: '',
            paths: [{
                cta: "You wanna hang out?",
                path: 18
            }, {
                cta: "[Light his birthday candles]",
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield14.gif",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield15.gif",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield16.gif",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield17.gif",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield18.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield19.gif",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield20.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield21.png",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield22.png",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield23.gif",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield24.gif",
            type: '',
            paths: [{
                cta: null,
                path: 25
            }]
        }, {
            id: 25,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield25.gif",
            type: '',
            paths: [{
                cta: null,
                path: 26
            }]
        }, {
            id: 26,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield26.png",
            type: '',
            paths: [{
                cta: null,
                path: 27
            }]
        }, {
            id: 27,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield27d.png",
            type: '',
            paths: [{
                cta: "[Give tip to Furnie]",
                path: 28
            }, {
                cta: "[Don't tip]",
                path: 29
            }]
        }, {
            id: 28,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield28.png",
            type: '',
            paths: [{
                cta: null,
                path: 30
            }]
        }, {
            id: 29,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield29.png",
            type: '',
            paths: [{
                cta: null,
                path: 30
            }]
        }, {
            id: 30,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield30.gif",
            type: '',
            paths: [{
                cta: null,
                path: 31
            }]
        }, {
            id: 31,
            points: 0,
            imgurl: "assets/img/darby-c4-shield/shield31.gif",
            type: 'end',
            paths: []
        }]
    });
});