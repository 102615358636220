define('digital-style/models/spaghetti/c4', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p00.png",
            type: 'start',
            paths: [{
                cta: null,
                path: '1-diag'
            }]
        },
        // {
        //     id: 1,
        //     points: 0,
        //     imgurl: "assets/img/spaghetti/c4/treasure_c4p01.png",
        //     type: '',
        //     paths: [
        //         {
        //             cta: null,
        //             path: '1-diag'
        //         }
        //     ]
        // },
        {
            id: '1-diag',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p01d.png",
            type: '',
            paths: [{
                cta: "Let's cook something new!",
                path: 2
            }, {
                cta: "Let's offer the leftovers!",
                path: '2-alt'
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p02.png",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: '2-alt',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p02_leftovers.png",
            type: '',
            paths: [{
                cta: null,
                path: '14-alt'
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p03.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p04.png",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p05d.png",
            type: '',
            paths: [{
                cta: 'Bread',
                path: "bread"
            }, {
                cta: 'Rice',
                path: "rice"
            }, {
                cta: 'Pasta',
                path: "pasta"
            }]
        }, {
            id: 'bread',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_bread.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 'rice',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_rice.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 'pasta',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_pasta.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p07.png",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p08d.png",
            type: '',
            paths: [{
                cta: "Fortified Soy Milk",
                path: 'soymilk'
            }, {
                cta: "Milk",
                path: 'milk'
            }]
        }, {
            id: 'soymilk',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_soymilk.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 'milk',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_milk.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p10.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p11d.png",
            type: '',
            paths: [{
                cta: 'Protein', //change this to Protein but keep the path to be the same
                path: 'meat'
            }, {
                cta: 'Eggs',
                path: 'eggs'
            }, {
                cta: 'Nuts and Legumes',
                path: 'nuts'
            }]
        }, {
            id: 'meat',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_meat.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 'eggs',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_eggs.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 'nuts',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4_nuts.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p14.png",
            type: '',
            paths: [{
                cta: null,
                path: 'finalrecipe'
            }]
        }, {
            id: 'finalrecipe',
            points: 0,
            imgurl: "",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: '14-alt',
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p14.png",
            type: '',
            paths: [{
                cta: null,
                path: '16'
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p16.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p17.gif",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p18.gif",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p19.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p20.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p21.gif",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p22.png",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p23.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p24.png",
            type: '',
            paths: [{
                cta: null,
                path: 25
            }]
        }, {
            id: 25,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p25.png",
            type: '',
            paths: [{
                cta: null,
                path: 26
            }]
        }, {
            id: 26,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p26.png",
            type: '',
            paths: [{
                cta: null,
                path: 27
            }]
        }, {
            id: 27,
            points: 0,
            imgurl: "assets/img/spaghetti/c4/treasure_c4p27.png",
            type: 'end',
            paths: []
        }]
    });
});

// {
//     id: 28,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p28.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 29
//         }
//     ]
// },
// {
//     id: 29,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p29.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 30
//         }
//     ]
// },
// {
//     id: 30,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p30.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 31
//         }
//     ]
// },
// {
//     id: 31,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p31.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 32
//         }
//     ]
// },
// {
//     id: 32,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p32.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 33
//         }
//     ]
// },
// {
//     id: 33,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p33.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 34
//         }
//     ]
// },
// {
//     id: 34,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p34.png",
//     type: '',
//     paths: [
//         {
//             cta: null,
//             path: 35
//         }
//     ]
// },
// {
//     id: 35,
//     points: 0,
//     imgurl: "assets/img/spaghetti/c4/treasure_c4p35.png",
//     type: 'end',
//     paths: [

//     ]
// },