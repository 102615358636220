define('digital-style/routes/comic/darby/c2-internets-robogle', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        // comic model to build the comic
        model: function model() {
            return this.store.find('darby/c2-pc');
        },
        actions: {
            // run this once the comic route is loaded

            didTransition: function didTransition() {
                // 	    	this.get('controller').send('initStore');
                setTimeout(function () {
                    // 	    	this.get('controller').send('initStore');
                    window.scrollTo(0, 0);
                    _ember['default'].$('#comic').removeClass('lock');
                }, 100);
            }

        }
    });
});