define('digital-style/routes/comic/darby/c2-pc', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		// comic model to build the comic
		model: function model() {
			return this.store.find('darby/c2-pc');
		},
		actions: {
			// run this once the comic route is loaded
			didTransition: function didTransition() {
				var t = this;
				setTimeout(function () {
					document.body.style.background = '#2b394d';
					window.scrollTo(0, 0);
					_ember['default'].$('#comic').addClass('lock');
					// 				t.send('startTime');
				}, 100);

				this.store.find('darby/c2-pc', 1).then(function (arr) {
					//Dev mode to set all the things if necessary

					//				arr.set('housecraftclue', true);
					//				arr.set('housecraftdownloaded', true);
					//				arr.set('housecraftopened', true);
					//				arr.set('blogclue', true);
					//				arr.set('antivirusclue', true);
					//				arr.set('antivirusdownloaded', true);
					//				arr.set('antivirusopened', true);
					//				arr.set('junkfolderclue', true);
					// 				arr.set('translatepanelsopened', true);
					//				arr.set('translateunlocked', true);

					//				arr.set('antivirusdownloaded', true);
					setTimeout(function () {

						var housecraftClue = arr.get('housecraftclue');
						var downloadedHousecraft = arr.get('housecraftdownloaded');
						var openedHouseCraft = arr.get('housecraftopened');

						var antivirusClue = arr.get('antivirusclue');
						var downloadedAntivirus = arr.get('antivirusdownloaded');
						var openedAntivirus = arr.get('antivirusopened');

						var junkfolderClue = arr.get('junkfolderclue');
						var blogClue = arr.get('blogclue');

						var translatepanelsopened = arr.get('translatepanelsopened');

						var translateunlocked = arr.get('translateunlocked');

						if (downloadedHousecraft === 'false' && housecraftClue !== 'false') {
							arr.set('housecraftdownloaded', true);
							t.send('animateProgressBar', '#housecraftloadicon', '#housecraftDownloadProgress .progress', "#housecraftDownloadProgress .progress-bar", "#housecraftdledicon", "#housecraftloadicon");
						}
						if (downloadedHousecraft !== 'false') {
							t.send('showDownloadedIcon', 200, "#housecraftDownloadProgress .progress", "#housecraftdledicon", "#housecraftloadicon");
						}

						if (downloadedAntivirus === 'false' && antivirusClue !== 'false') {
							arr.set('antivirusdownloaded', true);
							t.send('animateProgressBar', '#antivirusloadicon', '#antivirusDownloadProgress .progress', "#antivirusDownloadProgress .progress-bar", "#antivirusdledicon", "#antivirusloadicon");
						}

						if (downloadedAntivirus !== 'false') {
							t.send('showDownloadedIcon', 200, "#antivirusDownloadProgress .progress", "#antivirusdledicon", "#antivirusloadicon");
						}

						if (openedHouseCraft !== 'false' && openedAntivirus !== 'false' && junkfolderClue !== 'false' && blogClue !== 'false' && translatepanelsopened === 'false') {

							t.store.find('darby/c2-pc', 1).then(function (arr) {
								setTimeout(function () {
									console.log('all passed');
									arr.set('translatepanelsopened', 'true');
									_ember['default'].$("#translateDiscoveredBanner").delay(100).animate({ top: "+=200" }, 1000);
									_ember['default'].$(".darbyBtns").prop("disabled", true);
									_ember['default'].$(".DarbyUIButton").prop("disabled", true);
								}, 100);
							});
						}

						if (translateunlocked !== 'false') {
							setTimeout(function () {
								_ember['default'].$("#translateCodeBanner").delay(100).animate({ top: "+=200" }, 1000);
							}, 100);
						}

						if (openedAntivirus === 'false') {
							_ember['default'].$("#postAV").hide();
						} else {
							_ember['default'].$("#preAV").hide();
							_ember['default'].$("#scanNowBtn").hide();
						}
					}, 100);
				});
			},

			/*
           animateProgressBar:function(){
   			setTimeout(function () {
   				$('#housecraftloadicon').show();
   			    $('.progress').show();
   				$(".progress-bar").animate({
   				    width: "100%"
   				}, 2000, "linear");
   
   			}, 500);
   			
   			this.send('showDownloadedIcon', 4000);
           },
   */

			animateProgressBar: function animateProgressBar(icon, progress, progressbar, dledIcon, loadedIcon) {
				setTimeout(function () {
					_ember['default'].$(icon).show();
					_ember['default'].$(progress).show();
					_ember['default'].$(progressbar).animate({
						width: "100%"
					}, 2000, "linear");
				}, 500);

				this.send('showDownloadedIcon', 4000, progress, dledIcon, loadedIcon);
			},

			showDownloadedIcon: function showDownloadedIcon(time, progress, downloadedIcon, loadingIcon) {
				setTimeout(function () {
					_ember['default'].$(progress).hide();
					_ember['default'].$(downloadedIcon).show();
					_ember['default'].$(loadingIcon).hide();
				}, time);
			},

			/*
           startTime: function(){
   	        var today = new Date();
   		    var h = today.getHours();
   		    var m = today.getMinutes();
   		    var s = today.getSeconds();
   		    m = this.send('checkTime', m);
   		    s = this.send('checkTime', s);
   		    document.getElementById('clock').innerHTML = h + ":" + m + ":" + s;
   		    Ember.run.later((function() {
   				this.send('startTime');
   			}), 500);
   
           },
           
           checkTime: function(i) {
   		    if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
   		    return i;
   		},
   */

			willTransition: function willTransition() {
				document.body.style.background = '#FFFFFF';
			}

		}
	});
});