define('digital-style/models/darby/c5-shield', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench00.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench01.gif",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench02.gif",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench03.gif",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench04.png",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench05.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench06.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench07.gif",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench08.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench09.gif",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench10d.gif",
            type: '',
            paths: [{
                cta: "You are NOT a failure",
                path: 11
            }, {
                cta: "This isn't your fault!",
                path: 12
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench11.gif",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench12.gif",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench14.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench15.png",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench16.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench17.png",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench18.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench19.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench20.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench21.png",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench22.gif",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench23.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/bench24.gif",
            type: '',
            paths: [{
                cta: null,
                path: 601
            }]
        },

        //Chp 6 Below
        {
            id: 601,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p01.png",
            type: '',
            paths: [{
                cta: null,
                path: 602
            }]
        }, {
            id: 602,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p02d.png",
            type: '',
            paths: [{
                cta: "That was trippy",
                path: 603
            }, {
                cta: "Play again?",
                path: 604
            }]
        }, {
            id: 603,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p03.png",
            type: '',
            paths: [{
                cta: null,
                path: 605
            }]
        }, {
            id: 604,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p04.png",
            type: '',
            paths: [{
                cta: null,
                path: 605
            }]
        }, {
            id: 605,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p05.png",
            type: '',
            paths: [{
                cta: null,
                path: 606
            }]
        }, {
            id: 606,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p06shield.png",
            type: '',
            paths: [{
                cta: null,
                path: 607
            }]
        }, {
            id: 607,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p07d.png",
            type: '',
            paths: [{
                cta: "My dad.",
                path: 608
            }, {
                cta: "I'll think of someone.",
                path: 612
            }]
        }, {
            id: 608,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p08dad.gif",
            type: '',
            paths: [{
                cta: null,
                path: 609
            }]
        }, {
            id: 609,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p09shield.gif",
            type: '',
            paths: [{
                cta: null,
                path: 610
            }]
        }, {
            id: 610,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p10dad.png",
            type: '',
            paths: [{
                cta: null,
                path: 611
            }]
        }, {
            id: 611,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p11.png",
            type: 'end',
            paths: []
        }, {
            id: 612,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p08someone.gif",
            type: '',
            paths: [{
                cta: null,
                path: 613
            }]
        }, {
            id: 613,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p09shield.gif",
            type: '',
            paths: [{
                cta: null,
                path: 614
            }]
        }, {
            id: 614,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p10someone.png",
            type: '',
            paths: [{
                cta: null,
                path: 615
            }]
        }, {
            id: 615,
            points: 0,
            imgurl: "assets/img/darby-c5-shield/c6p11.png",
            type: 'end',
            paths: []
        }]
    });
});