define('digital-style/routes/comic/spaghetti/c3', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		controllerName: 'comic/spaghetti/spaghetti-main',
		// comic model to build the comic
		model: function model() {
			return this.store.find('comic');
		},
		actions: {
			// run this once the comic route is loaded
			didTransition: function didTransition() {
				var t = this;
				setTimeout(function () {

					window.scrollTo(0, 0);
					_ember['default'].$('#comic').addClass('lock');
					// 				t.send('startTime');
					_ember['default'].$('body').css({ 'width': window.innerWidth + 'px' });

					t.controller.set('modelToUse', 'spaghetti/c3');

					// clear all previous comic panels
					t.store.unloadAll('comic');

					// add the first panel of the model
					t.get('controller').send('addPanel', 0);

					document.body.style.background = '#e8c390';

					// remove scroll lock
					_ember['default'].$('#comic').removeClass('lock');

					// wait for DOM to be ready
					_ember['default'].$(document).ready(function () {
						//                     ts.init();
						t.store.find('spaghetti', 1).then(function (arr) {
							arr.set('spaghetti', 'true');
							arr.set('plate', 'true');
							arr.set('fruits', 'true');
							arr.set('grains', 'true');
							arr.set('dairy', 'true');
							arr.set('protein', 'true');
						});
						_ember['default'].$(".toggleJournalBtn").show();

						// var notified = false;
						var panels = ["#panel-8-water"];
						var documentHeightAtFirstChoice;
						_ember['default'].$(window).scroll(function () {
							setTimeout(function () {
								t.get('controller').send('topBannerScrollHandler');
								t.get('controller').send('spaghettiJournalScrollHandler', panels);
							}, 2000);

							// if ( notified === false){
							// 	var documentHeight = Ember.$(document).height()-300;

							// 	if(Ember.$(window).scrollTop() + Ember.$(window).height() >= documentHeight) {
							// 		notified = true;
							// 		documentHeightAtFirstChoice = Ember.$(document).height();
							// 		console.log(documentHeightAtFirstChoice);

							// 		Ember.$("#choiceInstructions").fadeIn(1000);
							//   		}
							// }

							//once the notification shows up and the users clicks, this will make the banner hide again
							// if (notified === true){
							// 	if (Ember.$(document).height() >= documentHeightAtFirstChoice+150){
							// 		Ember.$("#choiceInstructions").fadeOut(1000);
							// 	}
							// }
						});
					});
				}, 100);

				this.store.find('spaghetti', 1).then(function (arr) {
					//Dev mode to set all the things if necessary

					arr.set('test1', true);
				});
			},

			willTransition: function willTransition() {
				document.body.style.background = '#FFFFFF';
			}

		}
	});
});