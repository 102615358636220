define('digital-style/models/retro', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        imgurl: attr('string'),
        questions: attr(),
        answers: attr()
    }).reopenClass({
        FIXTURES: [{
            id: 1,
            imgurl: '//placehold.it/450x250',
            questions: [{
                text: "what day is it today?"
            }],
            answers: [{
                resp1: {
                    text: 'thursday',
                    correct: false
                },
                resp2: {
                    text: 'friday',
                    correct: false
                },
                resp3: {
                    text: 'saturday',
                    correct: false
                },
                resp4: {
                    text: 'redtruckday',
                    correct: true
                }

            }]
        }]
    });
});