define('digital-style/components/cta-list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',

        actions: {
            addPanel: function addPanel(nextPanelId) {
                //console.log('cta-list nextPanel');
                // console.log(nextPanelId);
                // this.send('recipeHandler', nextPanelId)

                var selected = '.path-' + nextPanelId + ':last';

                if (!$(selected).hasClass('selected') && !$(selected).hasClass('not')) {
                    // hide other paths
                    $(selected).addClass('selected').parents('section:first').find('.path').not(selected).addClass('not');

                    setTimeout(function () {
                        var wHeight = window.innerHeight,
                            wScroll = $(window).scrollTop();

                        // animate scrolling
                        $('html, body').animate({
                            scrollTop: wHeight * 0.95 + wScroll
                        }, {
                            duration: 1000
                        });
                    }, 500);

                    // bubble up the action addPanel - will go to comic-list's controller

                    this.sendAction('addPanel', nextPanelId);
                }
            },
            recipeHandler: function recipeHandler(nextPanelId) {
                console.log("recipeHandler cta");
                this.sendAction('recipeHandler', nextPanelId);
            }

        }
    });
});
/*globals $ */