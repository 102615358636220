define('digital-style/controllers/intro/intro-1', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        // content for intro.intro-1 route
        preload_1: 'assets/img/lab.png',
        preload_2: 'assets/img/screenfilter_withbackground.gif',
        preload_3: 'assets/img/retro/patuscreen_large.gif',
        img_1: 'assets/img/patu_01.png',
        copy_1: 'Hey! You\'re the new kid, right?',
        cta_1: 'Uh... yeah.',
        cta_2: 'What?'
    });
});