define('digital-style/models/ellen/03-good-help', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_3_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 264
            }]
        }, {
            id: 264,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 31
            }]
        }, {
            id: 31,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 32
            }]
        }, {
            id: 32,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 33
            }]
        }, {
            id: 33,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 34
            }]
        }, {
            id: 34,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 35
            }]
        }, {
            id: 35,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 36
            }]
        }, {
            id: 36,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 37
            }]
        }, {
            id: 37,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_goodsleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 38
            }]
        }, {
            id: 38,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 39
            }]
        }, {
            id: 39,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a_goodsleep.gif",
            type: '',
            paths: [{
                cta: null,
                path: 40
            }]
        }, {
            id: 40,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 41
            }]
        }, {
            id: 41,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 42
            }]
        }, {
            id: 42,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 43
            }]
        }, {
            id: 43,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 44
            }]
        }, {
            id: 44,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'hey! it was alright, you?',
                path: 45
            }, {
                cta: 'pretty awesomesauceee',
                path: 46
            }, {
                cta: ':) ok hbu?',
                path: 47
            }]
        }, {
            id: 45,
            points: 0,
            imgurl: "assets/img/ellen/ellenitwasalright.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 46,
            points: 0,
            imgurl: "assets/img/ellen/ellenawesomesauce.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 47,
            points: 0,
            imgurl: "assets/img/ellen/ellensmileyfaceemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 48,
            points: 0,
            imgurl: "assets/img/ellen/ellen50a.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 49
            }, {
                cta: 'Keep texting.',
                path: 121
            }]
        }, {
            id: 49,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_dohw.png",
            type: '',
            paths: [{
                cta: null,
                path: 50
            }]
        }, {
            id: 50,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_homeworktime.png",
            type: 'end',
            paths: []
        }, {
            id: 121,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_texttom.png",
            type: 'end2',
            paths: []
        }]
    });
});