define('digital-style/models/darby/c3', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/darby-c3/c3titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p01.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p02.png",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p03d.png",
            type: '',
            paths: [{
                cta: "I'll cheer you up!",
                path: 6
            }, {
                cta: "Is this about our stolen gems?",
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p04.png",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p05d.png",
            type: '',
            paths: [{
                cta: "It's gonna be okay!",
                path: 7
            }, {
                cta: "Have you told anyone about this?",
                path: 9
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p06.gif",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p07.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p08.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p10.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p11.gif",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p12d.gif",
            type: '',
            paths: [{
                cta: "Dude, you nearly hit us!",
                path: 14
            }, {
                cta: "Did you use up our gems?",
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p13.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p14.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p15.png",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p16.gif",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p17.gif",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p18.gif",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p19d.png",
            type: '',
            paths: [{
                cta: "Let's bounce his energy beams back at him",
                path: 20
            }, {
                cta: "Let's damage his suit with water",
                path: 22
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p20.gif",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p21.gif",
            type: 'end',
            paths: []
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p22.gif",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/darby-c3/c3p23.gif",
            type: 'end2',
            paths: []
        }]
    });
});