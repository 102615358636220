define('digital-style/routes/comic/darby/c2-translate', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        // comic model to build the comic
        controllerName: 'comic/story',
        model: function model() {
            return this.store.find('comic');
        },
        actions: {
            // run this once the comic route is loaded
            didTransition: function didTransition() {
                var t = this;

                // show scroll instructions
                setTimeout(function () {
                    // fix size
                    $('body').css({ 'width': window.innerWidth + 'px' });

                    t.controller.set('modelToUse', 'darby/c2');

                    document.body.style.background = '#2e343e';

                    // clear all previous comic panels

                    t.store.unloadAll('comic');

                    t.store.find('darby/c2-pc', 1).then(function (arr) {
                        var translatedone = arr.get('translatedone');
                        if (translatedone === 'false') {
                            t.get('controller').send('addPanel', 16);
                            arr.set('translateunlocked', 'true');
                            /*
                            						Ember.$('#retrospective').show();
                            						Ember.$('#retrospective2').hide();
                            */
                        } else {
                                t.get('controller').send('addPanel', 18);
                                /*
                                						Ember.$('#retrospective').hide();
                                						Ember.$('#retrospective2').show();
                                */
                            }
                    });

                    // remove scroll lock
                    _ember['default'].$('#comic').removeClass('lock');

                    // wait for DOM to be ready

                    $(document).ready(function () {
                        //                     ts.init();
                        /*
                        					t.store.find('darby/c2-pc', 1).then(function (arr) {
                        						var translatedone = arr.get('translatedone');
                        						if ( translatedone === 'false' ){
                        							Ember.$('#retrospective').show();
                        							Ember.$('#retrospective2').hide();
                        						}
                        						else{
                        							Ember.$('#retrospective').hide();
                        							Ember.$('#retrospective2').show();
                        						}
                        	
                        					});
                        */
                    });
                }, 100);
            }
        }
    });
});
/*globals $ */