define('digital-style/models/darby/c4-blaster', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster00.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster01.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster02.gif",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster03.gif",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster04.png",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster05.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster06.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster07.png",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster08.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster10.gif",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster11.png",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster12.gif",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster14.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster15.png",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster16.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster17.png",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster18.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster19.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster20.gif",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster21.gif",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster22.gif",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster23.gif",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster24.gif",
            type: '',
            paths: [{
                cta: null,
                path: 25
            }]
        }, {
            id: 25,
            points: 0,
            imgurl: "assets/img/darby-c4-blaster/blaster25.gif",
            type: 'end',
            paths: []
        }]
    });
});