define('digital-style/routes/comic/ellen/01-prologue', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        // comic model to build the comic
        controllerName: 'comic/story',
        model: function model() {
            return this.store.find('comic');
        },
        actions: {
            // run this once the comic route is loaded
            didTransition: function didTransition() {
                var t = this,
                    ts = {};

                // show scroll instructions
                setTimeout(function () {
                    // fix size
                    $('body').css({ 'width': window.innerWidth + 'px' });

                    t.controller.set('modelToUse', 'ellen/01-prologue');

                    // show onboarding tutorial
                    $('#how-to').fadeIn(250);

                    // clear all previous comic panels
                    t.store.unloadAll('comic');

                    // add the first panel of the model
                    t.get('controller').send('addPanel', 1);

                    // next instructions
                    //                 $('#alrighty').on('click', function (e) {
                    //                     e.preventDefault();
                    //
                    //                     $(this).parents('#how-to').fadeOut(250, function () {
                    // //                     $('#how-to-1').fadeOut(250, function () {
                    // //                         $('#how-to-2').fadeIn(250);
                    // 						//  $('html, body').animate({
                    //                         //     scrollTop: $('#panel-1').offset().top
                    //                         // }, {
                    //                         //     duration: 7000,
                    //                         //     complete: function () {
                    //                         //         // flash scroll notification
                    //                         //         var flash = setInterval(function () {
                    //                         //             $('#comic > .scroll-notify').fadeToggle(250);
                    //                         //         }, 1000);
                    //                         //
                    //                         //         // stop flashing
                    //                         //         setTimeout(function () {
                    //                         //             clearInterval(flash);
                    //                         //         }, 4000);
                    //                         //     }
                    //                         // });
                    //                     });
                    //                 });

                    // hide scroll instructions on click

                    /*
                                    $('#gotcha').on('click', function (e) {
                                        e.preventDefault();
                                        $('#comic').removeClass('lock');
                                        $(this).parents('#how-to').fadeOut(250, function () {
                                            $('html, body').animate({
                                                scrollTop: $('#panel-1').offset().top
                                            }, {
                                                duration: 7000,
                                                complete: function () {
                                                    // flash scroll notification
                                                    var flash = setInterval(function () {
                                                        $('#comic > .scroll-notify').fadeToggle(250);
                                                    }, 1000);
                    
                                                    // stop flashing
                                                    setTimeout(function () {
                                                        clearInterval(flash);
                                                    }, 4000);
                                                }
                                            });
                                        });
                                    });
                    */

                    // parallax code
                    ts = {
                        panel: $('#panel-0'),
                        panelHeight: 0,
                        scrollFromTop: 0,
                        windowHeight: window.innerHeight,
                        windowWidth: window.innerWidth,
                        allWrappers: [],
                        currentWrapper: null,
                        currentKeyframe: 0,
                        previousKeyframe: 0,
                        previousKeyframeDuration: 0,
                        keyframespx: [],
                        keyframes: [{
                            'wrapper': '#panel-0',
                            'duration': '200%',
                            'animations': [{
                                'selector': '#title h1',
                                'translateY': '200%',
                                'opacity': 0
                            }, {
                                'selector': '#sky1',
                                'translateY': '0'
                            }, {
                                'selector': '#sky2',
                                'translateY': '0'
                            }, {
                                'selector': '#stars',
                                'translateY': '-50%'
                            }, {
                                'selector': '#moon',
                                'translateY': '25%'
                            }]
                        }, {
                            'duration': '100%',
                            'animations': []
                        }],
                        animateEls: function animateEls() {
                            'use strict';
                            var i, animation, translateY, translateX, scale, rotate, opacity;

                            for (i = 0; i < ts.keyframespx[ts.currentKeyframe].animations.length; i++) {
                                animation = ts.keyframespx[ts.currentKeyframe].animations[i];
                                translateY = ts.calcPropValue(animation, 'translateY');
                                translateX = ts.calcPropValue(animation, 'translateX');
                                scale = ts.calcPropValue(animation, 'scale');
                                rotate = ts.calcPropValue(animation, 'rotate');
                                opacity = ts.calcPropValue(animation, 'opacity');

                                if (!!$(ts.keyframespx[ts.currentKeyframe].wrapper).find(animation.selector).length) {
                                    $(ts.keyframespx[ts.currentKeyframe].wrapper).find(animation.selector).css({
                                        'transform': 'translate3d(' + translateX + 'px, ' + translateY + 'px, 0) scale(' + scale + ') rotate(' + rotate + 'deg)',
                                        'opacity': opacity
                                    });
                                }
                            }
                        },
                        doParallax: function doParallax() {
                            'use strict';
                            window.requestAnimationFrame(function () {
                                // change scrollFromTop var
                                ts.changeScrollTop();

                                if (ts.scrollFromTop > 0 && ts.scrollFromTop <= ts.panelHeight - ts.windowHeight) {
                                    ts.animateEls();
                                    ts.changeKeyframe();
                                }
                            });
                        },
                        setupSizes: function setupSizes() {
                            'use strict';
                            ts.windowHeight = window.innerHeight;
                            ts.windowWidth = $('body').width();
                        },
                        setupPage: function setupPage() {
                            'use strict';
                            var i;

                            for (i = 0; i < ts.keyframespx.length; i++) {
                                ts.panelHeight += ts.keyframespx[i].duration;
                                if ($.inArray(ts.keyframespx[i].wrapper, ts.allWrappers) === -1) {
                                    ts.allWrappers.push(ts.keyframespx[i].wrapper);
                                }
                            }

                            ts.panel.height(ts.panelHeight);
                            window.scrollTo(0, 0);
                            ts.currentWrapper = ts.allWrappers[0];
                            $(ts.currentWrapper).show();
                        },
                        setupEvents: function setupEvents() {
                            'use strict';
                            // window resize
                            /*
                                                    $(window).on('resize', function () {
                                                        ts.setupSizes();
                                                        ts.changeToPixels();
                                                        ts.setupPage();
                                                    });
                            */

                            // interval update on 60 fps
                            ts.update = setInterval(ts.doParallax, 10);
                        },
                        percentageToPx: function percentageToPx(axis, percentage) {
                            'use strict';
                            var p, r;

                            if (typeof percentage === "string" && !!percentage.match(/%/g)) {
                                p = parseInt(percentage.replace('%', ''), 10);

                                // change X axis to pixels
                                if (axis === 'x') {
                                    r = window.innerWidth / 100 * p;
                                }

                                // change Y axis to pixels
                                if (axis === 'y') {
                                    r = window.innerHeight / 100 * p;
                                }
                            }

                            return r;
                        },
                        cloneArray: function cloneArray(arr) {
                            'use strict';
                            var r = JSON.parse(JSON.stringify(arr));
                            return r;
                        },
                        changeToPixels: function changeToPixels() {
                            'use strict';
                            var cloned = ts.cloneArray(ts.keyframes),
                                h,
                                i,
                                j,
                                k,
                                value;

                            for (i = 0; i < cloned.length; i++) {
                                // duration changes
                                h = ts.percentageToPx('y', cloned[i].duration);
                                cloned[i]['duration'] = h;

                                // animation changes
                                for (j = 0; j < cloned[i]['animations'].length; j++) {
                                    Object.keys(cloned[i]['animations'][j]).forEach(function (o) {
                                        value = cloned[i]['animations'][j][o];
                                        if (o !== 'selector') {
                                            if (value instanceof Array) {
                                                for (k = 0; k < value.length; k++) {
                                                    if (typeof value[k] === "string") {
                                                        switch (o) {
                                                            case 'translateX':
                                                                value[k] = ts.percentageToPx('x', value);
                                                                break;
                                                            case 'translateY':
                                                                value[k] = ts.percentageToPx('y', value);
                                                                break;
                                                        }
                                                    }
                                                }
                                            } else {
                                                if (typeof value === "string") {
                                                    if (o === 'translateY') {
                                                        value = [ts.getDefaultPropertyValue(o), ts.percentageToPx('y', value)];
                                                    } else {
                                                        value = [ts.getDefaultPropertyValue(o), ts.percentageToPx('x', value)];
                                                    }
                                                } else {
                                                    value = [ts.getDefaultPropertyValue(o), value];
                                                }
                                            }
                                            cloned[i]['animations'][j][o] = value;
                                        }
                                    });
                                }
                            }

                            ts.keyframespx = cloned;
                        },
                        changeScrollTop: function changeScrollTop() {
                            'use strict';
                            ts.scrollFromTop = $(window).scrollTop();
                            ts.relativeScrollFromTop = ts.scrollFromTop - ts.previousKeyframeDuration;
                        },
                        changeKeyframe: function changeKeyframe() {
                            'use strict';
                            if (ts.scrollFromTop > ts.previousKeyframeDuration + ts.keyframespx[ts.currentKeyframe].duration) {
                                ts.previousKeyframeDuration += ts.keyframespx[ts.currentKeyframe].duration;
                                ts.currentKeyframe++;
                                ts.showCurrentWrapper();
                            } else if (ts.scrollFromTop < ts.previousKeyframeDuration) {
                                ts.currentKeyframe--;
                                ts.previousKeyframeDuration -= ts.keyframespx[ts.currentKeyframe].duration;
                                ts.showCurrentWrapper();
                            }
                        },
                        showCurrentWrapper: function showCurrentWrapper() {
                            'use strict';
                            if (ts.keyframespx[ts.currentKeyframe].wrapper !== ts.currentWrapper) {
                                $(ts.currentWrapper).hide();
                                $(ts.keyframespx[ts.currentKeyframe].wrapper).show();
                                ts.currentWrapper = ts.keyframespx[ts.currentKeyframe].wrapper;
                            }
                        },
                        calcPropValue: function calcPropValue(animation, property) {
                            'use strict';
                            var c = ts.currentKeyframe,
                                value = animation[property];

                            if (value) {
                                value = ts.easeInOutQuad(ts.relativeScrollFromTop, value[0], value[1] - value[0], ts.keyframespx[c].duration);
                            } else {
                                value = ts.getDefaultPropertyValue(property);
                            }

                            // value = +value.toFixed(2)

                            return value;
                        },
                        easeInOutQuad: function easeInOutQuad(t, b, c, d) {
                            'use strict';
                            //sinusoadial in and out
                            var r = -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;

                            return r;
                        },
                        getDefaultPropertyValue: function getDefaultPropertyValue(property) {
                            'use strict';
                            switch (property) {
                                case 'translateX':
                                    return 0;
                                case 'translateY':
                                    return 0;
                                case 'scale':
                                    return 1;
                                case 'rotate':
                                    return 0;
                                case 'opacity':
                                    return 1;
                                default:
                                    return null;
                            }
                        },
                        fromEdges: function fromEdges(axis, el) {
                            'use strict';
                            var r;

                            // get how far the element is from a specific
                            if (!!el.length) {
                                if (axis === 'x') {
                                    r = el.offset().left;
                                }
                                if (axis === 'y') {
                                    r = el.offset().top;
                                }
                            }

                            return r;
                        },
                        debug: function debug(m) {
                            'use strict';
                            // debugging function
                            if (!!console) {
                                console.log(m);
                            }
                        },
                        init: function init() {
                            'use strict';
                            ts.changeToPixels();
                            ts.setupPage();
                            ts.setupEvents();
                        }
                    };

                    // wait for DOM to be ready
                    $(document).ready(function () {
                        $('#comic').removeClass('lock');
                        ts.init();

                        $(window).scroll(function () {

                            setTimeout(function () {
                                t.get('controller').send('topBannerScrollHandler');
                            }, 2000);
                        });
                    });
                }, 100);
            }
        }
    });
});
/*globals $ */