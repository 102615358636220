define('digital-style/routes/site/main', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {
                // var t = this;
                // wait a little for DOM to be really ready
                // setTimeout(function () {
                // $('#jazz').get(0).play();
                // $('#rain').get(0).play();
                // $('#rain').get(0).volume = 0.50;
                // $('.bgmusic').each(function(){
                //     this.play();
                //     // this.prop("volume", 0.5);
                //     this.volume = 0.5;
                // });
                // t.controllerFor('application').set('audio_paused', false);
                // console.log(t.controllerFor('application').get('audio_paused'));

                // }, 20);
            },
            // run this before transition happens
            willTransition: function willTransition() {}
        }
    });
});