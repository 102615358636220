define('digital-style/controllers/comic/spaghetti/c4', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({
        needs: ['application'],
        scrollInstructions: false,
        lp_goodbadsleep: false,
        lp_artgameshang: false,
        final_dish_num: 0,
        /*
            charId: function () {
                return this.get('controllers.application').get('character');
            }.property('controllers.application.character'),
        */
        modelToUse: '',
        actions: {
            addPanel: function addPanel(nextPanelId, time) {

                var t = this,

                //                 c = t.get('charId'),
                c = t.get('modelToUse'),
                    idx,
                    opts,
                    img,
                    imgName,
                    panelcls,
                    intx,
                    times = !!time ? parseInt(time, 10) + 1 : 1,
                    isSecondTime = times === 2;

                // find the the store that matches the character selected, then...
                this.store.find(c, nextPanelId).then(function (arr) {
                    idx = arr.get('id');

                    switch (idx) {
                        case 'bread':
                            t.set('final_dish_num', t.get('final_dish_num') + 1);
                            break;
                        case 'rice':
                            {}
                            t.set('final_dish_num', t.get('final_dish_num') + 7);
                            break;
                        case 'pasta':
                            t.set('final_dish_num', t.get('final_dish_num') + 13);
                            break;
                        case 'soymilk':
                            t.set('final_dish_num', t.get('final_dish_num') + 0);
                            break;
                        case 'milk':
                            t.set('final_dish_num', t.get('final_dish_num') + 3);
                            break;
                        case 'meat':
                            t.set('final_dish_num', t.get('final_dish_num') + 0);
                            break;
                        case 'eggs':
                            t.set('final_dish_num', t.get('final_dish_num') + 1);
                            break;
                        case 'nuts':
                            t.set('final_dish_num', t.get('final_dish_num') + 2);
                            break;
                        default:
                            t.set('final_dish_num', t.get('final_dish_num') + 0);
                            break;
                    }
                    console.log("dishnum" + t.get('final_dish_num'));
                    // t.set('final_dish_num', t.get('final_dish_num')+dishNum);
                    // if (idx == 	'bread'){
                    // 	t.set('final_dish_num', t.get('final_dish_num')+1);
                    // }

                    console.log(idx);
                    if (idx === 'finalrecipe') {
                        var final_dish = t.get('final_dish_num');
                        console.log(final_dish);
                        img = "assets/img/spaghetti/c4/finaldishes/" + final_dish + ".png";
                        console.log("finalrecipe");
                        console.log(img);
                    } else {
                        img = arr.get('imgurl');
                    }

                    opts = arr.get('paths');
                    // img = arr.get('imgurl'); MOVED ABOVE
                    imgName = img.split('/').pop().split('.');
                    imgName.splice(-1, 1);
                    panelcls = imgName.join('').split('-')[0];

                    console.log(img);
                    // add new panel to the comic modelToUse
                    t.store.createRecord('comic', {
                        id: idx,
                        type: arr.get('type'),
                        imgurl: img,
                        cls: panelcls,
                        paths: opts
                    }).save();

                    setTimeout(function () {
                        // load next panel if there's only one path
                        if (opts.length === 1) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();

                            // send action to add next panel
                            t.send('addPanel', arr.get('paths')[0]['path'], times);

                            if (isSecondTime) {
                                // flash scroll notification
                                intx = setInterval(function () {
                                    $('#comic > .scroll-notify').fadeToggle(250);
                                }, 500);

                                // stop flashing
                                setTimeout(function () {
                                    clearInterval(intx);
                                }, 3500);
                            }
                        } else if (opts.length === 0) {
                            // hide the paths element
                            $('#panel-' + idx).find('.paths').hide();
                        }

                        if (arr.get('type') === 'end') {
                            $('#story #retrospective').show();
                        }
                        if (arr.get('type') === 'end2') {
                            $('#story #retrospective2').show();
                        }
                        if (arr.get('type') === 'end3') {
                            t.set('ending', '1');
                        }
                        if (arr.get('type') === 'end4') {
                            t.set('ending', '2');
                        }
                        if (arr.get('type') === 'end5') {
                            var end = t.get('ending');
                            if (end === '1') {
                                $('#story #retrospective').show();
                            }
                            if (end === '2') {
                                $('#story #retrospective2').show();
                            }
                        }
                        if (t.get('scrollInstructions') === false) {
                            _ember['default'].$("#topPasswordOrInstructions").animate({ top: "+=200" }, 1000);
                            t.set('scrollInstructions', true);
                        }
                    }, 100);
                });
            },
            hideLearningPanel: function hideLearningPanel(id) {
                document.getElementById(id).style.display = 'none';
                _ember['default'].$(".learning-point-overlay").fadeOut(400);
            },
            showLearningPanel: function showLearningPanel(id) {
                document.getElementById(id).style.display = 'inline';
                _ember['default'].$("#" + id + "Banner").hide();
                _ember['default'].$(".learning-point-overlay").fadeIn(400);
            },
            // isScrolledIntoView:function(trigger, elem){

            topBannerScrollHandler: function topBannerScrollHandler() {
                if ($(window).scrollTop() > 500) {
                    _ember['default'].$("#topPasswordOrInstructions").fadeOut();
                }
                // console.log("#panel-5".offsetHeight);
                var docViewTop = _ember['default'].$(window).scrollTop();
                var docViewBottom = docViewTop + $(window).height();

                var elemTop = _ember['default'].$("#panel-5").offset().top;
                var elemBottom = elemTop + $(_ember['default'].$("#panel-5")).height();

                console.log(elemBottom <= docViewBottom && elemTop >= docViewTop);
            },

            // recipeHandler:function(){
            //     this.store.find('spaghetti', 1).then(function (arr) {
            //         //Dev mode to set all the things if necessary
            //         console.log("here")
            //         arr.set('recipeCalculator', 2);

            //     });
            // },

            spaghettiJournalScrollHandler: function spaghettiJournalScrollHandler() {
                // var continueTextLP = ['#panel-62', '#panel-107', '#panel-134', '#panel-157'];
                // var textLaterLP = ['#panel-269', '#panel-279', '#panel-290', '#panel-302'];
                if ($("#story").hasClass("spaghetti-c1")) {
                    // var store = this.get('targetObject.store');
                    if ($('#panel-6').length === 1) {
                        this.store.find('spaghetti', 1).then(function (arr) {
                            _ember['default'].$("#thatsBaadLearningPoint").fadeIn(1000);
                        });
                    }
                }
                // if ($( "#story" ).hasClass("spaghetti-c1") && this.get('lp_goodbadsleep') === false){
                //     if ($(continueTextLP[i]).length === 1){

                //     for (var i = 0; i < continueTextLP.length; i++) {
                //         if ($(continueTextLP[i]).length === 1){
                //             // Ember.$("#thatsBaadLPBanner").fadeIn(1000);

                //             this.set('lp_goodbadsleep', true);
                //         }

                //         if ($(textLaterLP[i]).length === 1){
                //             Ember.$("#thatsGoodLP").fadeIn(1000);
                //             this.set('lp_goodbadsleep', true);
                //         }
                //     }
                // }
            }

        }

    });
});
/*globals $ */