define('digital-style/models/comic', ['exports', 'ember-data'], function (exports, _emberData) {

    var attr = _emberData['default'].attr;

    exports['default'] = _emberData['default'].Model.extend({
        type: attr('string'),
        imgurl: attr('string'),
        cls: attr('string'),
        ctas: attr(),
        paths: attr()
    }).reopenClass({
        FIXTURES: []
    });
});