define('digital-style/controllers/intro/intro-3', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        needs: ['application'],
        // content for intro.intro-3 route\
        preload_1: 'assets/img/lab.png',
        preload_2: 'assets/img/screenfilter_withbackground.gif',
        preload_3: 'assets/img/retro/patuscreen_large.gif',
        img_1: '//placehold.it/500x500',
        copy_1: 'Let\'s start with your name:',
        cta_1: 'Enter.',
        // actions for intro.intro-1 route
        actions: {
            insertName: function insertName() {
                var t = this;
                //console.log('Received: ' + this.get('userName'));

                // get the data from the user's input
                var userInput = this.get('userNameInput');

                if (!!userInput) {
                    // set the userName in the application's controller
                    t.get('controllers.application').set('userName', userInput);

                    //console.log('Saved: ' + this.controllerFor('application').get('userName'));

                    // set class to success
                    $('#userNameInput').addClass('success');

                    // transition to the next route after 1 second
                    setTimeout(function () {
                        t.transitionToRoute('intro.intro-4');
                    }, 500);
                } else {
                    $('#userNameInput').addClass('error');
                }
            },
            updateState: function updateState() {
                // remove both success and error classes when user starts typing
                $('#userNameInput').removeClass('success error');
            }
        }
    });
});
/*globals $ */