define('digital-style/routes/comic/darby/c3', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        // comic model to build the comic
        controllerName: 'comic/story',
        model: function model() {
            return this.store.find('comic');
        },
        actions: {
            // run this once the comic route is loaded
            didTransition: function didTransition() {
                var t = this;

                // show scroll instructions
                setTimeout(function () {
                    // fix size
                    //                 $('body').css({'width': window.innerWidth + 'px'});

                    t.controller.set('modelToUse', 'darby/c3');

                    // clear all previous comic panels
                    t.store.unloadAll('comic');

                    // add the first panel of the model
                    t.get('controller').send('addPanel', 0);

                    document.body.style.background = '#2e343e';

                    // remove scroll lock
                    $('#comic').removeClass('lock');

                    // wait for DOM to be ready
                    _ember['default'].$(document).ready(function () {
                        $(window).scroll(function () {
                            setTimeout(function () {
                                t.get('controller').send('topBannerScrollHandler');
                            }, 3000);
                        });
                    });
                }, 100);
            }
        }
    });
});
/*globals $ */

/*
        willTransition: function () {
 	        window.scrollTo(0,0);
        }
*/