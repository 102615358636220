define('digital-style/models/ellen', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 'first',
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight_2.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/ellen01_1.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue.png",
            type: '',
            paths: [{
                cta: 'Bring it!',
                path: 3
            }, {
                cta: 'Not really...',
                path: 4
            }, {
                cta: 'Hang on.',
                path: 5
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue_a.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue_b.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/ellen/ellen0dialogue_c.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/ellen02_1.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/ellen03.png",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/ellen/ellen04.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/ellen/ellen05.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/ellen/ellen06.png",
            type: '',
            paths: [{
                cta: 'Go to bed.',
                path: 11
            }, {
                cta: 'Study longer.',
                path: 172
            }, {
                cta: 'Listen to music.',
                path: 173
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/ellen/ellen08.png",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/ellen/elleng09.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/ellen/elleng14.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/ellen/ellen16.png",
            type: '',
            paths: [{
                cta: null,
                path: 260
            }]
        },
        /*
                {
                    id: 15,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen18.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 16
                        }
                    ]
                },
        */
        {
            id: 16,
            points: 0,
            imgurl: "assets/img/ellen/ellen19.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/ellen/ellen20.png",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/ellen/ellen21.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/ellen/elleng22.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/ellen/elleng23.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/ellen/elleng24.png",
            type: '',
            paths: [{
                cta: null,
                path: 262
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/ellen/ellen25.png",
            type: '',
            paths: [{
                cta: 'Sure.',
                path: 23
            }, {
                cta: 'No way...',
                path: 25
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/ellen/ellen25a.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/ellen/ellen27.png",
            type: '',
            paths: [{
                cta: null,
                path: 26
            }]
        }, {
            id: 25,
            points: 0,
            imgurl: "assets/img/ellen/ellen26.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 26,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.png",
            type: '',
            paths: [{
                cta: 'Sure.',
                path: 27
            }, {
                cta: 'What\'s this for?',
                path: 76
            }, {
                cta: 'No, thanks.',
                path: 77
            }]
        }, {
            id: 27,
            points: 0,
            imgurl: "assets/img/ellen/ellen29.png",
            type: '',
            paths: [{
                cta: null,
                path: 28
            }]
        }, {
            id: 28,
            points: 0,
            imgurl: "assets/img/ellen/ellen30.png",
            type: '',
            paths: [{
                cta: 'Why us?',
                path: 29
            }, {
                cta: 'You want us to look through a dumpster...?',
                path: 78
            }]
        }, {
            id: 29,
            points: 0,
            imgurl: "assets/img/ellen/ellen31.png",
            type: '',
            paths: [{
                cta: 'We\'ll do it.',
                path: 30
            }, {
                cta: 'This is sketchy.',
                path: 79
            }, {
                cta: 'Sorry, bud...',
                path: 80
            }]
        }, {
            id: 30,
            points: 0,
            imgurl: "assets/img/ellen/ellen32_accept.png",
            type: '',
            paths: [{
                cta: null,
                path: 264
            }]
        }, {
            id: 31,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: '',
            paths: [{
                cta: null,
                path: 32
            }]
        }, {
            id: 32,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 33
            }]
        }, {
            id: 33,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 34
            }]
        }, {
            id: 34,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 35
            }]
        }, {
            id: 35,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 36
            }]
        }, {
            id: 36,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 37
            }]
        }, {
            id: 37,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_goodsleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 38
            }]
        }, {
            id: 38,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 39
            }]
        }, {
            id: 39,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a_goodsleep.gif",
            type: '',
            paths: [{
                cta: null,
                path: 40
            }]
        }, {
            id: 40,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 41
            }]
        }, {
            id: 41,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 42
            }]
        }, {
            id: 42,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 43
            }]
        }, {
            id: 43,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 44
            }]
        }, {
            id: 44,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'hey! it was alright, you?',
                path: 45
            }, {
                cta: 'pretty awesomesauceee',
                path: 46
            }, {
                cta: ':) ok hbu?',
                path: 47
            }]
        }, {
            id: 45,
            points: 0,
            imgurl: "assets/img/ellen/ellenitwasalright.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 46,
            points: 0,
            imgurl: "assets/img/ellen/ellenawesomesauce.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 47,
            points: 0,
            imgurl: "assets/img/ellen/ellensmileyfaceemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 48,
            points: 0,
            imgurl: "assets/img/ellen/ellen50a.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 49
            }, {
                cta: 'Keep texting.',
                path: 121
            }]
        }, {
            id: 49,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_dohw.png",
            type: '',
            paths: [{
                cta: null,
                path: 50
            }]
        }, {
            id: 50,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_homeworktime.png",
            type: '',
            paths: [{
                cta: null,
                path: 51
            }]
        }, {
            id: 51,
            points: 0,
            imgurl: "assets/img/ellen/ellen53.png",
            type: '',
            paths: [{
                cta: null,
                path: 52
            }]
        }, {
            id: 52,
            points: 0,
            imgurl: "assets/img/ellen/ellen54.png",
            type: '',
            paths: [{
                cta: null,
                path: 53
            }]
        }, {
            id: 53,
            points: 0,
            imgurl: "assets/img/ellen/ellen55.png",
            type: '',
            paths: [{
                cta: null,
                path: 54
            }]
        }, {
            id: 54,
            points: 0,
            imgurl: "assets/img/ellen/ellen56.png",
            type: '',
            paths: [{
                cta: null,
                path: 55
            }]
        }, {
            id: 55,
            points: 0,
            imgurl: "assets/img/ellen/ellen57.png",
            type: '',
            paths: [{
                cta: null,
                path: 56
            }]
        }, {
            id: 56,
            points: 0,
            imgurl: "assets/img/ellen/ellen58.png",
            type: '',
            paths: [{
                cta: null,
                path: 57
            }]
        }, {
            id: 57,
            points: 0,
            imgurl: "assets/img/ellen/ellen59.png",
            type: '',
            paths: [{
                cta: null,
                path: 58
            }]
        }, {
            id: 58,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 59
            }]
        }, {
            id: 59,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 60
            }]
        }, {
            id: 60,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 61
            }]
        }, {
            id: 61,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 268
            }]
        }, {
            id: 62,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 63
            }]
        }, {
            id: 63,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 64
            }]
        }, {
            id: 64,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 65
            }]
        }, {
            id: 65,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 66
            }]
        }, {
            id: 66,
            points: 0,
            imgurl: "assets/img/ellen/ellen68.png",
            type: '',
            paths: [{
                cta: null,
                path: 67
            }]
        }, {
            id: 67,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 68
            }]
        }, {
            id: 68,
            points: 0,
            imgurl: "assets/img/ellen/ellen70_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 69
            }]
        }, {
            id: 69,
            points: 0,
            imgurl: "assets/img/ellen/ellen71_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 72
            }]
        },
        /*
                {
                    id: 70,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 73
                        }
                    ]
                },
        */

        /*
                {
                    id: 71,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 72
                        }
                    ]
                },
        */

        {
            id: 72,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 73
            }]
        }, {
            id: 73,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 74
            }]
        }, {
            id: 74,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 75
            }]
        }, {
            id: 75,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 244
            }]
        }, {
            id: 76,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.5b.png",
            type: '',
            paths: [{
                cta: null,
                path: 27
            }]
        }, {
            id: 77,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.5c.png",
            type: '',
            paths: [{
                cta: 'Okay, what\'s up?',
                path: 27
            }, {
                cta: 'What\'s the project?',
                path: 76
            }, {
                cta: 'No, thanks.',
                path: 80
            }]
        }, {
            id: 78,
            points: 0,
            imgurl: "assets/img/ellen/ellen30.5b.png",
            type: '',
            paths: [{
                cta: null,
                path: 29
            }]
        }, {
            id: 79,
            points: 0,
            imgurl: "assets/img/ellen/ellen31.5b.png",
            type: '',
            paths: [{
                cta: 'Okay.',
                path: 30
            }, {
                cta: 'No, sorry.',
                path: 80
            }]
        }, {
            id: 80,
            points: 0,
            imgurl: "assets/img/ellen/ellen32_reject.png",
            type: '',
            paths: [{
                cta: null,
                path: 266
            }]
        }, {
            id: 81,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: '',
            paths: [{
                cta: null,
                path: 82
            }]
        }, {
            id: 82,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 83
            }]
        }, {
            id: 83,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 84
            }]
        }, {
            id: 84,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 85
            }]
        }, {
            id: 85,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 86
            }]
        }, {
            id: 86,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 87
            }]
        }, {
            id: 87,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_goodsleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 88
            }]
        }, {
            id: 88,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 89
            }]
        }, {
            id: 89,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a_goodsleep.gif",
            type: '',
            paths: [{
                cta: null,
                path: 90
            }]
        }, {
            id: 90,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 91
            }]
        }, {
            id: 91,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 92
            }]
        }, {
            id: 92,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 93
            }]
        }, {
            id: 93,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 94
            }]
        }, {
            id: 94,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'hey! it was alright, you?',
                path: 95
            }, {
                cta: 'pretty awesomesauceee',
                path: 96
            }, {
                cta: ':) ok hbu?',
                path: 97
            }]
        }, {
            id: 95,
            points: 0,
            imgurl: "assets/img/ellen/ellenitwasalright.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 96,
            points: 0,
            imgurl: "assets/img/ellen/ellenawesomesauce.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 97,
            points: 0,
            imgurl: "assets/img/ellen/ellensmileyfaceemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 98,
            points: 0,
            imgurl: "assets/img/ellen/ellen50a.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 99
            }, {
                cta: 'Keep texting.',
                path: 148
            }]
        }, {
            id: 99,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_dohw.png",
            type: '',
            paths: [{
                cta: null,
                path: 100
            }]
        }, {
            id: 100,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_homeworktime.png",
            type: '',
            paths: [{
                cta: null,
                path: 101
            }]
        }, {
            id: 101,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 102
            }]
        }, {
            id: 102,
            points: 0,
            imgurl: "assets/img/ellen/ellen53_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 103
            }]
        }, {
            id: 103,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 104
            }]
        }, {
            id: 104,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 105
            }]
        }, {
            id: 105,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 106
            }]
        }, {
            id: 106,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 289
            }]
        }, {
            id: 107,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 108
            }]
        }, {
            id: 108,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 109
            }]
        }, {
            id: 109,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 110
            }]
        }, {
            id: 110,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 111
            }]
        }, {
            id: 111,
            points: 0,
            imgurl: "assets/img/ellen/ellen68_spazz.png",
            type: '',
            paths: [{
                cta: null,
                path: 112
            }]
        }, {
            id: 112,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 113
            }]
        }, {
            id: 113,
            points: 0,
            imgurl: "assets/img/ellen/ellen70_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 114
            }]
        }, {
            id: 114,
            points: 0,
            imgurl: "assets/img/ellen/ellen71_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 117
            }]
        },
        /*
                {
                    id: 115,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 116
                        }
                    ]
                },
                {
                    id: 116,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 117
                        }
                    ]
                },
        */
        {
            id: 117,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 118
            }]
        }, {
            id: 118,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 119
            }]
        }, {
            id: 119,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 120
            }]
        }, {
            id: 120,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 250
            }]
        }, {
            id: 121,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_texttom.png",
            type: '',
            paths: [{
                cta: null,
                path: 122
            }]
        }, {
            id: 122,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_t.png",
            type: '',
            paths: [{
                cta: null,
                path: 123
            }]
        }, {
            id: 123,
            points: 0,
            imgurl: "assets/img/ellen/ellen54.png",
            type: '',
            paths: [{
                cta: null,
                path: 124
            }]
        }, {
            id: 124,
            points: 0,
            imgurl: "assets/img/ellen/ellen55.png",
            type: '',
            paths: [{
                cta: null,
                path: 125
            }]
        }, {
            id: 125,
            points: 0,
            imgurl: "assets/img/ellen/ellen56.png",
            type: '',
            paths: [{
                cta: null,
                path: 126
            }]
        }, {
            id: 126,
            points: 0,
            imgurl: "assets/img/ellen/ellen57.png",
            type: '',
            paths: [{
                cta: null,
                path: 127
            }]
        }, {
            id: 127,
            points: 0,
            imgurl: "assets/img/ellen/ellen58.png",
            type: '',
            paths: [{
                cta: null,
                path: 128
            }]
        }, {
            id: 128,
            points: 0,
            imgurl: "assets/img/ellen/ellen59_t.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 129
            }, {
                cta: 'Go to bed.',
                path: 130
            }]
        }, {
            id: 129,
            points: 0,
            imgurl: "assets/img/ellen/ellen60_hw.png",
            type: '',
            paths: [{
                cta: null,
                path: 130
            }]
        }, {
            id: 130,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 131
            }]
        }, {
            id: 131,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 132
            }]
        }, {
            id: 132,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 133
            }]
        }, {
            id: 133,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 278
            }]
        }, {
            id: 134,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 135
            }]
        }, {
            id: 135,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 136
            }]
        }, {
            id: 136,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 137
            }]
        }, {
            id: 137,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 138
            }]
        }, {
            id: 138,
            points: 0,
            imgurl: "assets/img/ellen/ellen68.png",
            type: '',
            paths: [{
                cta: null,
                path: 139
            }]
        }, {
            id: 139,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 140
            }]
        }, {
            id: 140,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 141
            }]
        }, {
            id: 141,
            points: 0,
            imgurl: "assets/img/ellen/ellen71.png",
            type: '',
            paths: [{
                cta: null,
                path: 144
            }]
        },
        /*
                {
                    id: 142,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 143
                        }
                    ]
                },
                {
                    id: 143,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 144
                        }
                    ]
                },
        */
        {
            id: 144,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 145
            }]
        }, {
            id: 145,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 146
            }]
        }, {
            id: 146,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 147
            }]
        }, {
            id: 147,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 247
            }]
        }, {
            id: 148,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_texttom.png",
            type: '',
            paths: [{
                cta: null,
                path: 149
            }]
        }, {
            id: 149,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 151
            }]
        },
        /*
                {
                    id: 150,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen52_bf.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 151
                        }
                    ]
                },
        */
        {
            id: 151,
            points: 0,
            imgurl: "assets/img/ellen/ellen53_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 152
            }]
        }, {
            id: 152,
            points: 0,
            imgurl: "assets/img/ellen/ellen60_hw.png",
            type: '',
            paths: [{
                cta: null,
                path: 153
            }]
        }, {
            id: 153,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 154
            }]
        }, {
            id: 154,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 155
            }]
        }, {
            id: 155,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 156
            }]
        }, {
            id: 156,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 301
            }]
        }, {
            id: 157,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 158
            }]
        }, {
            id: 158,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 159
            }]
        }, {
            id: 159,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 160
            }]
        }, {
            id: 160,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 161
            }]
        }, {
            id: 161,
            points: 0,
            imgurl: "assets/img/ellen/ellen68_spazz.png",
            type: '',
            paths: [{
                cta: null,
                path: 162
            }]
        }, {
            id: 162,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 163
            }]
        }, {
            id: 163,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 164
            }]
        }, {
            id: 164,
            points: 0,
            imgurl: "assets/img/ellen/ellen71.png",
            type: '',
            paths: [{
                cta: null,
                path: 167
            }]
        },
        /*
                {
                    id: 165,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 166
                        }
                    ]
                },
                {
                    id: 166,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 167
                        }
                    ]
                },
        */
        {
            id: 167,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 168
            }]
        }, {
            id: 168,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 170
            }]
        }, {
            id: 170,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 171
            }]
        }, {
            id: 171,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 255
            }]
        }, {
            id: 172,
            points: 0,
            imgurl: "assets/img/ellen/ellen07alternate.png",
            type: '',
            paths: [{
                cta: null,
                path: 174
            }]
        }, {
            id: 173,
            points: 0,
            imgurl: "assets/img/ellen/ellen07.png",
            type: '',
            paths: [{
                cta: null,
                path: 174
            }]
        }, {
            id: 174,
            points: 0,
            imgurl: "assets/img/ellen/ellen08.png",
            type: '',
            paths: [{
                cta: null,
                path: 175
            }]
        }, {
            id: 175,
            points: 0,
            imgurl: "assets/img/ellen/ellen09.png",
            type: '',
            paths: [{
                cta: null,
                path: 176
            }]
        }, {
            id: 176,
            points: 0,
            imgurl: "assets/img/ellen/ellen10.png",
            type: '',
            paths: [{
                cta: null,
                path: 177
            }]
        }, {
            id: 177,
            points: 0,
            imgurl: "assets/img/ellen/ellen11.png",
            type: '',
            paths: [{
                cta: null,
                path: 178
            }]
        }, {
            id: 178,
            points: 0,
            imgurl: "assets/img/ellen/ellen12.png",
            type: '',
            paths: [{
                cta: null,
                path: 179
            }]
        }, {
            id: 179,
            points: 0,
            imgurl: "assets/img/ellen/ellen13.png",
            type: '',
            paths: [{
                cta: null,
                path: 180
            }]
        }, {
            id: 180,
            points: 0,
            imgurl: "assets/img/ellen/ellen_busdaydream.png",
            type: '',
            paths: [{
                cta: null,
                path: 181
            }]
        }, {
            id: 181,
            points: 0,
            imgurl: "assets/img/ellen/ellen15.png",
            type: '',
            paths: [{
                cta: null,
                path: 182
            }]
        }, {
            id: 182,
            points: 0,
            imgurl: "assets/img/ellen/ellen16.png",
            type: '',
            paths: [{
                cta: null,
                path: 261
            }]
        },
        /*
                {
                    id: 183,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen17.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path:
                        }
                    ]
                },
        */
        /*
                {
                    id: 184,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen18.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 185
                        }
                    ]
                },
        */
        {
            id: 185,
            points: 0,
            imgurl: "assets/img/ellen/ellen19.png",
            type: '',
            paths: [{
                cta: null,
                path: 186
            }]
        }, {
            id: 186,
            points: 0,
            imgurl: "assets/img/ellen/ellen20.png",
            type: '',
            paths: [{
                cta: null,
                path: 187
            }]
        }, {
            id: 187,
            points: 0,
            imgurl: "assets/img/ellen/ellen21.png",
            type: '',
            paths: [{
                cta: null,
                path: 188
            }]
        }, {
            id: 188,
            points: 0,
            imgurl: "assets/img/ellen/ellen22.png",
            type: '',
            paths: [{
                cta: null,
                path: 189
            }]
        }, {
            id: 189,
            points: 0,
            imgurl: "assets/img/ellen/ellen23.png",
            type: '',
            paths: [{
                cta: null,
                path: 263
            }]
        }, {
            id: 190,
            points: 0,
            imgurl: "assets/img/ellen/ellen25.png",
            type: '',
            paths: [{
                cta: 'Sure.',
                path: 191
            }, {
                cta: 'No way...',
                path: 192
            }]
        }, {
            id: 191,
            points: 0,
            imgurl: "assets/img/ellen/ellen25a.png",
            type: '',
            paths: [{
                cta: null,
                path: 193
            }]
        }, {
            id: 192,
            points: 0,
            imgurl: "assets/img/ellen/ellen26.png",
            type: '',
            paths: [{
                cta: null,
                path: 193
            }]
        }, {
            id: 193,
            points: 0,
            imgurl: "assets/img/ellen/ellen27.png",
            type: '',
            paths: [{
                cta: null,
                path: 194
            }]
        }, {
            id: 194,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.png",
            type: '',
            paths: [{
                cta: 'Sure.',
                path: 195
            }, {
                cta: 'What\'s this for?',
                path: 217
            }, {
                cta: 'No, thanks.',
                path: 218
            }]
        }, {
            id: 195,
            points: 0,
            imgurl: "assets/img/ellen/ellen29.png",
            type: '',
            paths: [{
                cta: null,
                path: 196
            }]
        }, {
            id: 196,
            points: 0,
            imgurl: "assets/img/ellen/ellen30.png",
            type: '',
            paths: [{
                cta: 'Why us?',
                path: 197
            }, {
                cta: 'You want us to look through a dumpster...?',
                path: 238
            }]
        }, {
            id: 197,
            points: 0,
            imgurl: "assets/img/ellen/ellen31.png",
            type: '',
            paths: [{
                cta: 'We\'ll do it.',
                path: 198
            }, {
                cta: 'This is sketchy.',
                path: 239
            }, {
                cta: 'Sorry, bud...',
                path: 219
            }]
        }, {
            id: 198,
            points: 0,
            imgurl: "assets/img/ellen/ellen32_accept.png",
            type: '',
            paths: [{
                cta: null,
                path: 265
            }]
        }, {
            id: 199,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: '',
            paths: [{
                cta: null,
                path: 200
            }]
        }, {
            id: 200,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 201
            }]
        }, {
            id: 201,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 240
            }]
        }, {
            id: 202,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 203
            }]
        }, {
            id: 203,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 204
            }]
        }, {
            id: 204,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 205
            }]
        }, {
            id: 205,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_nosleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 206
            }]
        }, {
            id: 206,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 207
            }]
        }, {
            id: 207,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a.png",
            type: '',
            paths: [{
                cta: 'Be quiet!',
                path: 208
            }, {
                cta: 'Ignore.',
                path: 209
            }]
        }, {
            id: 208,
            points: 0,
            imgurl: "assets/img/ellen/ellen_getlostpippy.png",
            type: '',
            paths: [{
                cta: null,
                path: 209
            }]
        }, {
            id: 209,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 210
            }]
        }, {
            id: 210,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 211
            }]
        }, {
            id: 211,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 212
            }]
        }, {
            id: 212,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 213
            }]
        }, {
            id: 213,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'bad news bears...',
                path: 214
            }, {
                cta: 'ughh... so bad!! you?',
                path: 215
            }, {
                cta: ':\'(',
                path: 216
            }]
        }, {
            id: 214,
            points: 0,
            imgurl: "assets/img/ellen/ellenbadnewsbears.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 215,
            points: 0,
            imgurl: "assets/img/ellen/ellenughhsobad.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 216,
            points: 0,
            imgurl: "assets/img/ellen/ellencryingemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 48
            }]
        }, {
            id: 217,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.5b.png",
            type: '',
            paths: [{
                cta: null,
                path: 195
            }]
        }, {
            id: 218,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.5c.png",
            type: '',
            paths: [{
                cta: 'Okay, what\'s up?',
                path: 195
            }, {
                cta: 'What\'s the project?',
                path: 217
            }, {
                cta: 'No, thanks.',
                path: 219
            }]
        }, {
            id: 219,
            points: 0,
            imgurl: "assets/img/ellen/ellen32_reject.png",
            type: '',
            paths: [{
                cta: null,
                path: 267
            }]
        }, {
            id: 220,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: '',
            paths: [{
                cta: null,
                path: 221
            }]
        }, {
            id: 221,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 222
            }]
        }, {
            id: 222,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 242
            }]
        }, {
            id: 223,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 224
            }]
        }, {
            id: 224,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 225
            }]
        }, {
            id: 225,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 226
            }]
        }, {
            id: 226,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_nosleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 227
            }]
        }, {
            id: 227,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 228
            }]
        }, {
            id: 228,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a.png",
            type: '',
            paths: [{
                cta: 'Be quiet!',
                path: 229
            }, {
                cta: 'Ignore.',
                path: 230
            }]
        }, {
            id: 229,
            points: 0,
            imgurl: "assets/img/ellen/ellen_getlostpippy.png",
            type: '',
            paths: [{
                cta: null,
                path: 230
            }]
        }, {
            id: 230,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 231
            }]
        }, {
            id: 231,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 232
            }]
        }, {
            id: 232,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 233
            }]
        }, {
            id: 233,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 234
            }]
        }, {
            id: 234,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'bad news bears...',
                path: 235
            }, {
                cta: 'ughh... so bad!! you?',
                path: 236
            }, {
                cta: '>.< :\'(',
                path: 237
            }]
        }, {
            id: 235,
            points: 0,
            imgurl: "assets/img/ellen/ellenbadnewsbears.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 236,
            points: 0,
            imgurl: "assets/img/ellen/ellenughhsobad.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 237,
            points: 0,
            imgurl: "assets/img/ellen/ellencryingemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 238,
            points: 0,
            imgurl: "assets/img/ellen/ellen30.5b.png",
            type: '',
            paths: [{
                cta: null,
                path: 197
            }]
        }, {
            id: 239,
            points: 0,
            imgurl: "assets/img/ellen/ellen31.5b.png",
            type: '',
            paths: [{
                cta: 'Okay.',
                path: 198
            }, {
                cta: 'No, sorry.',
                path: 219
            }]
        }, {
            id: 240,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream1.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 241
            }]
        }, {
            id: 241,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream2.png",
            type: '',
            paths: [{
                cta: null,
                path: 202
            }]
        }, {
            id: 242,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream1.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 243
            }]
        }, {
            id: 243,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream2.png",
            type: '',
            paths: [{
                cta: null,
                path: 223
            }]
        }, {
            id: 244,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 245
            }]
        }, {
            id: 245,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 246
            }]
        }, {
            id: 246,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }, {
            id: 247,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 248
            }]
        }, {
            id: 248,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 249
            }]
        }, {
            id: 249,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }, {
            id: 250,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu1.png",
            type: '',
            paths: [{
                cta: null,
                path: 251
            }]
        }, {
            id: 251,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu2.png",
            type: '',
            paths: [{
                cta: null,
                path: 252
            }]
        }, {
            id: 252,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 253
            }]
        }, {
            id: 253,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 254
            }]
        }, {
            id: 254,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }, {
            id: 255,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu1.png",
            type: '',
            paths: [{
                cta: null,
                path: 256
            }]
        }, {
            id: 256,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu2.png",
            type: '',
            paths: [{
                cta: null,
                path: 257
            }]
        }, {
            id: 257,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 258
            }]
        }, {
            id: 258,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 259
            }]
        }, {
            id: 259,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }, {
            id: 260,
            points: 0,
            imgurl: "assets/img/bg/bg_school.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 261,
            points: 0,
            imgurl: "assets/img/bg/bg_school.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 185
            }]
        }, {
            id: 262,
            points: 0,
            imgurl: "assets/img/bg/bg_recess.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 263,
            points: 0,
            imgurl: "assets/img/bg/bg_recess.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 190
            }]
        }, {
            id: 264,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 31
            }]
        }, {
            id: 265,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 199
            }]
        }, {
            id: 266,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 81
            }]
        }, {
            id: 267,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 220
            }]
        }, {
            id: 268,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Continue Texting",
                path: 62
            }, {
                cta: "Text later",
                path: 269
            }]
        }, {
            id: 269,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 270
            }]
        }, {
            id: 270,
            points: 0,
            imgurl: "assets/img/ellen/ellen81nofight.png",
            type: '',
            paths: [{
                cta: null,
                path: 271
            }]
        }, {
            id: 271,
            points: 0,
            imgurl: "assets/img/ellen/ellen83.png",
            type: '',
            paths: [{
                cta: null,
                path: 272
            }]
        }, {
            id: 272,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 273
            }]
        }, {
            id: 273,
            points: 0,
            imgurl: "assets/img/ellen/ellen85.png",
            type: '',
            paths: [{
                cta: null,
                path: 274
            }]
        }, {
            id: 274,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 275
            }]
        }, {
            id: 275,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 276
            }]
        }, {
            id: 276,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 277
            }]
        }, {
            id: 277,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: 'end',
            paths: []
        }, {
            id: 278,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Text later",
                path: 279
            }, {
                cta: "Continue Texting",
                path: 134
            }]
        }, {
            id: 279,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 280
            }]
        }, {
            id: 280,
            points: 0,
            imgurl: "assets/img/ellen/ellen81nofight.png",
            type: '',
            paths: [{
                cta: null,
                path: 281
            }]
        }, {
            id: 281,
            points: 0,
            imgurl: "assets/img/ellen/ellen83.png",
            type: '',
            paths: [{
                cta: null,
                path: 282
            }]
        }, {
            id: 282,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 283
            }]
        }, {
            id: 283,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 284
            }]
        }, {
            id: 284,
            points: 0,
            imgurl: "assets/img/ellen/ellen86.png",
            type: '',
            paths: [{
                cta: null,
                path: 285
            }]
        }, {
            id: 285,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 286
            }]
        }, {
            id: 286,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 287
            }]
        }, {
            id: 287,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 288
            }]
        }, {
            id: 288,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: 'end',
            paths: []
        }, {
            id: 289,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Text later",
                path: 290
            }, {
                cta: "Continue Texting",
                path: 107
            }]
        }, {
            id: 290,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 291
            }]
        }, {
            id: 291,
            points: 0,
            imgurl: "assets/img/ellen/ellen81.png",
            type: '',
            paths: [{
                cta: null,
                path: 292
            }]
        }, {
            id: 292,
            points: 0,
            imgurl: "assets/img/ellen/ellen82.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 293
            }]
        }, {
            id: 293,
            points: 0,
            imgurl: "assets/img/ellen/ellen83cathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 294
            }]
        }, {
            id: 294,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 295
            }]
        }, {
            id: 295,
            points: 0,
            imgurl: "assets/img/ellen/ellen85.png",
            type: '',
            paths: [{
                cta: null,
                path: 296
            }]
        }, {
            id: 296,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 297
            }]
        }, {
            id: 297,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 298
            }]
        }, {
            id: 298,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 299
            }]
        }, {
            id: 299,
            points: 0,
            imgurl: "assets/img/ellen/ellen76thankcathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 300
            }]
        }, {
            id: 300,
            points: 0,
            imgurl: "assets/img/ellen/ellen77thankcathy.png",
            type: 'end',
            paths: []
        }, {
            id: 301,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Text later",
                path: 302
            }, {
                cta: "Continue Texting",
                path: 157
            }]
        }, {
            id: 302,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 303
            }]
        }, {
            id: 303,
            points: 0,
            imgurl: "assets/img/ellen/ellen81.png",
            type: '',
            paths: [{
                cta: null,
                path: 304
            }]
        }, {
            id: 304,
            points: 0,
            imgurl: "assets/img/ellen/ellen82.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 305
            }]
        }, {
            id: 305,
            points: 0,
            imgurl: "assets/img/ellen/ellen83cathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 306
            }]
        }, {
            id: 306,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 307
            }]
        }, {
            id: 307,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 308
            }]
        }, {
            id: 308,
            points: 0,
            imgurl: "assets/img/ellen/ellen86.png",
            type: '',
            paths: [{
                cta: null,
                path: 309
            }]
        }, {
            id: 309,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 310
            }]
        }, {
            id: 310,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 311
            }]
        }, {
            id: 311,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 312
            }]
        }, {
            id: 312,
            points: 0,
            imgurl: "assets/img/ellen/ellen76thankcathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 313
            }]
        }, {
            id: 313,
            points: 0,
            imgurl: "assets/img/ellen/ellen77thankcathy.png",
            type: 'end',
            paths: []
        }]
    });
});