define('digital-style/controllers/intro/intro-5', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        needs: ['application'],
        // content for intro.intro-5 route
        preload_1: 'assets/img/lab.png',
        preload_2: 'assets/img/screenfilter_withbackground.gif',
        preload_3: 'assets/img/retro/patuscreen_large.gif',
        img_1: 'assets/img/patu_02.png',
        copy_1: 'Cool! It\'s time to go',
        cta_1: 'Okay',
        userNameSaved: (function () {
            // get username from the application's controller
            return this.get('controllers.application').get('userName');
        }).property('controllers.application.userName')
    });
});