define('digital-style/routes/intro/intro-4', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {
                //console.log('intro-4 done');

                // apply background transition on the way back from retrospective
                setTimeout(function () {
                    if ($('#intro-bg').hasClass('off')) {
                        var introBg = $('#intro-bg'),
                            introImg = introBg.find('img').height();
                        introBg.toggleClass('on off').css('transform', 'translateY(' + (-introImg + introImg * 0.425) + 'px)');
                    }
                }, 100);
            },
            // run this before transition happens
            willTransition: function willTransition(transition) {
                // wait 1 second for the out-animations to happen
                // only run if intro-2 has class 'on'
                if ($('#intro-4').hasClass('on')) {
                    // remove on class; add off
                    $('#intro-4').toggleClass('on off');

                    // abort transition
                    transition.abort();

                    // wait 1 second to retry - next time on class won't be here and transition will happen
                    setTimeout(function () {
                        transition.retry();
                    }, 1000);
                }
            }
        }
    });
});
/*globals $ */