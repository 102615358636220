define('digital-style/components/blank-darby-window', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		actions: {
			toggleWindow: function toggleWindow(input) {
				// 			var id = $("button").closest("div.ember-view").eq();
				// 			var id = $("button").parents('div:eq(0)').attr('id');
				document.getElementById(input).style.display = 'none';
			}
		}
	});
});