define('digital-style/models/ellen/03-bad-reject', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_3_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 267
            }]
        }, {
            id: 267,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 220
            }]
        }, {
            id: 220,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 221
            }]
        }, {
            id: 221,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 222
            }]
        }, {
            id: 222,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 242
            }]
        }, {
            id: 242,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream1.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 243
            }]
        }, {
            id: 243,
            points: 0,
            imgurl: "assets/img/ellen/ellen35daydream2.png",
            type: '',
            paths: [{
                cta: null,
                path: 223
            }]
        }, {
            id: 223,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 224
            }]
        }, {
            id: 224,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 225
            }]
        }, {
            id: 225,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 226
            }]
        }, {
            id: 226,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_nosleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 227
            }]
        }, {
            id: 227,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 228
            }]
        }, {
            id: 228,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a.png",
            type: '',
            paths: [{
                cta: 'Be quiet!',
                path: 229
            }, {
                cta: 'Ignore.',
                path: 230
            }]
        }, {
            id: 229,
            points: 0,
            imgurl: "assets/img/ellen/ellen_getlostpippy.png",
            type: '',
            paths: [{
                cta: null,
                path: 230
            }]
        }, {
            id: 230,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 231
            }]
        }, {
            id: 231,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 232
            }]
        }, {
            id: 232,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 233
            }]
        }, {
            id: 233,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 234
            }]
        }, {
            id: 234,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'bad news bears...',
                path: 235
            }, {
                cta: 'ughh... so bad!! you?',
                path: 236
            }, {
                cta: '>.< :\'(',
                path: 237
            }]
        }, {
            id: 235,
            points: 0,
            imgurl: "assets/img/ellen/ellenbadnewsbears.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 236,
            points: 0,
            imgurl: "assets/img/ellen/ellenughhsobad.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 237,
            points: 0,
            imgurl: "assets/img/ellen/ellencryingemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 98,
            points: 0,
            imgurl: "assets/img/ellen/ellen50a.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 99
            }, {
                cta: 'Keep texting.',
                path: 148
            }]
        }, {
            id: 99,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_dohw.png",
            type: '',
            paths: [{
                cta: null,
                path: 100
            }]
        }, {
            id: 100,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_homeworktime.png",
            type: 'end',
            paths: []
        }, {
            id: 148,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_texttom.png",
            type: 'end2',
            paths: []
        }]
    });
});