define('digital-style/routes/intro/intro-2', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {
                //console.log('intro-2 done');

                // wait a little for DOM to be really ready
                setTimeout(function () {
                    $('#intro-2').toggleClass('on off');
                }, 100);
            },
            // run this before transition happens
            willTransition: function willTransition(transition) {
                // wait 1 second for the out-animations to happen
                // only run if intro-2 has class 'on'
                if ($('#intro-2').hasClass('on')) {
                    // remove on class; add off
                    $('#intro-2').toggleClass('on off');

                    // abort transition
                    transition.abort();

                    // wait 1 second to retry - next time on class won't be here and transition will happen
                    setTimeout(function () {
                        transition.retry();
                    }, 1000);
                }
            }
        }
    });
});
/*globals $ */