define('digital-style/controllers/comic/darby/c2-internets-blog', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].ArrayController.extend({
		needs: ['application'],
		actions: {
			setCurrentPage: function setCurrentPage() {
				var t = this;
				var documentHeight = $(document).height() / 2;
				$(window).scroll(function () {
					if ($(window).scrollTop() >= documentHeight) {
						t.send('activateClueBanner');
					}
				});
			},

			activateClueBanner: function activateClueBanner() {

				this.store.find('darby/c2-pc', 1).then(function (arr) {
					var visitedBefore = arr.get('blogclue');
					if (visitedBefore === 'false') {
						arr.set('blogclue', 'true');
						$("#notificationBanner").fadeIn(1000);
						$(".closeButtonOnBar").prop("disabled", true);
					}
				});
			}

		}

	});
});
/*globals $ */