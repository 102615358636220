define('digital-style/models/ellen/04-help-text', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_4_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 122
            }]
        }, {
            id: 122,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_t.png",
            type: '',
            paths: [{
                cta: null,
                path: 123
            }]
        }, {
            id: 123,
            points: 0,
            imgurl: "assets/img/ellen/ellen54.png",
            type: '',
            paths: [{
                cta: null,
                path: 124
            }]
        }, {
            id: 124,
            points: 0,
            imgurl: "assets/img/ellen/ellen55.png",
            type: '',
            paths: [{
                cta: null,
                path: 125
            }]
        }, {
            id: 125,
            points: 0,
            imgurl: "assets/img/ellen/ellen56.png",
            type: '',
            paths: [{
                cta: null,
                path: 126
            }]
        }, {
            id: 126,
            points: 0,
            imgurl: "assets/img/ellen/ellen57.png",
            type: '',
            paths: [{
                cta: null,
                path: 127
            }]
        }, {
            id: 127,
            points: 0,
            imgurl: "assets/img/ellen/ellen58.png",
            type: '',
            paths: [{
                cta: null,
                path: 128
            }]
        }, {
            id: 128,
            points: 0,
            imgurl: "assets/img/ellen/ellen59_t.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 129
            }, {
                cta: 'Go to bed.',
                path: 130
            }]
        }, {
            id: 129,
            points: 0,
            imgurl: "assets/img/ellen/ellen60_hw.png",
            type: '',
            paths: [{
                cta: null,
                path: 130
            }]
        }, {
            id: 130,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 131
            }]
        }, {
            id: 131,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 132
            }]
        }, {
            id: 132,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 133
            }]
        }, {
            id: 133,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 278
            }]
        }, {
            id: 278,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Text later",
                path: 279
            }, {
                cta: "Continue Texting",
                path: 134
            }]
        }, {
            id: 279,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 280
            }]
        }, {
            id: 280,
            points: 0,
            imgurl: "assets/img/ellen/ellen81nofight.png",
            type: '',
            paths: [{
                cta: null,
                path: 281
            }]
        }, {
            id: 281,
            points: 0,
            imgurl: "assets/img/ellen/ellen83.png",
            type: '',
            paths: [{
                cta: null,
                path: 282
            }]
        }, {
            id: 282,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 283
            }]
        }, {
            id: 283,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 284
            }]
        }, {
            id: 284,
            points: 0,
            imgurl: "assets/img/ellen/ellen86.png",
            type: '',
            paths: [{
                cta: null,
                path: 285
            }]
        }, {
            id: 285,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 286
            }]
        }, {
            id: 286,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 287
            }]
        }, {
            id: 287,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 288
            }]
        }, {
            id: 288,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: 'end',
            paths: []
        }, {
            id: 134,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 135
            }]
        }, {
            id: 135,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 136
            }]
        }, {
            id: 136,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 137
            }]
        }, {
            id: 137,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 138
            }]
        }, {
            id: 138,
            points: 0,
            imgurl: "assets/img/ellen/ellen68.png",
            type: '',
            paths: [{
                cta: null,
                path: 139
            }]
        }, {
            id: 139,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 140
            }]
        }, {
            id: 140,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 141
            }]
        }, {
            id: 141,
            points: 0,
            imgurl: "assets/img/ellen/ellen71.png",
            type: '',
            paths: [{
                cta: null,
                path: 144
            }]
        },
        /*
                {
                    id: 142,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 143
                        }
                    ]
                },
                {
                    id: 143,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 144
                        }
                    ]
                },
        */
        {
            id: 144,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 145
            }]
        }, {
            id: 145,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 146
            }]
        }, {
            id: 146,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 147
            }]
        }, {
            id: 147,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 247
            }]
        }, {
            id: 247,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 248
            }]
        }, {
            id: 248,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 249
            }]
        }, {
            id: 249,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }]
    });
});