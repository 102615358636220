define('digital-style/routes/comic/darby/c2-internets-dapple-support', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // comic model to build the comic
    model: function model() {
      return this.store.find('darby/c2-pc');
    },
    actions: {
      // run this once the comic route is loaded

      /*
              didTransition: function () {
      // 	    	this.get('controller').send('initStore');
      			$('#comic').removeClass('lock');
              }
              
      */

      downloadAntivirus: function downloadAntivirus() {
        this.store.find('darby/c2-pc', 1).then(function (arr) {
          arr.set('antivirusclue', true);
        });
      }
    }
  });
});