define('digital-style/models/spaghetti/c2', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p00.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p01.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p02.png",
            type: '',
            paths: [{
                cta: null,
                path: 3
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p03.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p04.png",
            type: '',
            paths: [{
                cta: null,
                path: 5
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p05.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p06.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p07.png",
            type: '',
            paths: [{
                cta: null,
                path: 8
            }]
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p08.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p10.png",
            type: '',
            paths: [{
                cta: null,
                path: 11
            }]
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p11.png",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p12.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p13.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p14.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p15.png",
            type: '',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p16.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p17.png",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p18.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p19.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p20.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p21.png",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p22.png",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p23.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p24.png",
            type: '',
            paths: [{
                cta: null,
                path: 25
            }]
        }, {
            id: 25,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p25.png",
            type: '',
            paths: [{
                cta: null,
                path: "25-diag"
            }]
        }, {
            id: '25-diag',
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p25.5.png",
            type: '',
            paths: [{
                cta: 'What does that mean...?',
                path: "26-a"
            }, {
                cta: "I'm listening",
                path: "26-b"
            }]
        }, {
            id: '26-a',
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p26.1.png",
            type: '',
            paths: [{
                cta: null,
                path: "26-fruits"
            }]
        }, {
            id: '26-b',
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p26.2.png",
            type: '',
            paths: [{
                cta: null,
                path: "26-fruits"
            }]
        }, {
            id: "26-fruits",
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p26.png",
            type: '',
            paths: [{
                cta: null,
                path: 27
            }]
        }, {
            id: 27,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p27.png",
            type: '',
            paths: [{
                cta: null,
                path: "28-grains"
            }]
        }, {
            id: "28-grains",
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p28.png",
            type: '',
            paths: [{
                cta: null,
                path: '29-grains'
            }]
        }, {
            id: '29-grains',
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p29.png",
            type: '',
            paths: [{
                cta: null,
                path: "30-dairy"
            }]
        }, {
            id: "30-dairy",
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p30.png",
            type: '',
            paths: [{
                cta: null,
                path: "30-diag"
            }]
        }, {
            id: "30-diag",
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p30.5.png",
            type: '',
            paths: [{
                cta: "Wait, is that it?",
                path: 31
            }, {
                cta: "Something's missing...",
                path: '31-alt'
            }]
        }, {
            id: 31,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p31.png",
            type: '',
            paths: [{
                cta: null,
                path: 32
            }]
        }, {
            id: '31-alt',
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p31.2.png",
            type: '',
            paths: [{
                cta: null,
                path: 32
            }]
        }, {
            id: 32,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p32.png",
            type: '',
            paths: [{
                cta: null,
                path: "33-protein"
            }]
        }, {
            id: "33-protein",
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p33.png",
            type: '',
            paths: [{
                cta: null,
                path: 34
            }]
        }, {
            id: 34,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p34.png",
            type: '',
            paths: [{
                cta: null,
                path: 35
            }]
        }, {
            id: 35,
            points: 0,
            imgurl: "assets/img/spaghetti/c2/treasure_c2p35.png",
            type: 'end',
            paths: []
        }]
    });
});