define('digital-style/models/ellen/03-good-reject', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_3_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 266
            }]
        }, {
            id: 266,
            points: 0,
            imgurl: "assets/img/bg/bg_soccer.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 81
            }]
        }, {
            id: 81,
            points: 0,
            imgurl: "assets/img/ellen/ellen33.png",
            type: 'start',
            paths: [{
                cta: null,
                path: 82
            }]
        }, {
            id: 82,
            points: 0,
            imgurl: "assets/img/ellen/ellen34.png",
            type: '',
            paths: [{
                cta: null,
                path: 83
            }]
        }, {
            id: 83,
            points: 0,
            imgurl: "assets/img/ellen/ellen35.png",
            type: '',
            paths: [{
                cta: null,
                path: 84
            }]
        }, {
            id: 84,
            points: 0,
            imgurl: "assets/img/ellen/ellen36a.png",
            type: '',
            paths: [{
                cta: null,
                path: 85
            }]
        }, {
            id: 85,
            points: 0,
            imgurl: "assets/img/ellen/ellen37a.png",
            type: '',
            paths: [{
                cta: null,
                path: 86
            }]
        }, {
            id: 86,
            points: 0,
            imgurl: "assets/img/ellen/ellen38a.png",
            type: '',
            paths: [{
                cta: null,
                path: 87
            }]
        }, {
            id: 87,
            points: 0,
            imgurl: "assets/img/ellen/ellen39a_goodsleep.png",
            type: '',
            paths: [{
                cta: null,
                path: 88
            }]
        }, {
            id: 88,
            points: 0,
            imgurl: "assets/img/ellen/ellen40a.png",
            type: '',
            paths: [{
                cta: null,
                path: 89
            }]
        }, {
            id: 89,
            points: 0,
            imgurl: "assets/img/ellen/ellen41a_goodsleep.gif",
            type: '',
            paths: [{
                cta: null,
                path: 90
            }]
        }, {
            id: 90,
            points: 0,
            imgurl: "assets/img/ellen/ellenHousenight.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 91
            }]
        }, {
            id: 91,
            points: 0,
            imgurl: "assets/img/ellen/ellen45a.png",
            type: '',
            paths: [{
                cta: null,
                path: 92
            }]
        }, {
            id: 92,
            points: 0,
            imgurl: "assets/img/ellen/ellen46a.png",
            type: '',
            paths: [{
                cta: null,
                path: 93
            }]
        }, {
            id: 93,
            points: 0,
            imgurl: "assets/img/ellen/ellen47a.png",
            type: '',
            paths: [{
                cta: null,
                path: 94
            }]
        }, {
            id: 94,
            points: 0,
            imgurl: "assets/img/ellen/ellen48a.png",
            type: '',
            paths: [{
                cta: 'hey! it was alright, you?',
                path: 95
            }, {
                cta: 'pretty awesomesauceee',
                path: 96
            }, {
                cta: ':) ok hbu?',
                path: 97
            }]
        }, {
            id: 95,
            points: 0,
            imgurl: "assets/img/ellen/ellenitwasalright.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 96,
            points: 0,
            imgurl: "assets/img/ellen/ellenawesomesauce.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 97,
            points: 0,
            imgurl: "assets/img/ellen/ellensmileyfaceemoji.png",
            type: '',
            paths: [{
                cta: null,
                path: 98
            }]
        }, {
            id: 98,
            points: 0,
            imgurl: "assets/img/ellen/ellen50a.png",
            type: '',
            paths: [{
                cta: 'Do homework.',
                path: 99
            }, {
                cta: 'Keep texting.',
                path: 148
            }]
        }, {
            id: 99,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_dohw.png",
            type: '',
            paths: [{
                cta: null,
                path: 100
            }]
        }, {
            id: 100,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_homeworktime.png",
            type: 'end',
            paths: []
        }, {
            id: 148,
            points: 0,
            imgurl: "assets/img/ellen/ellen51_texttom.png",
            type: 'end2',
            paths: []
        }]
    });
});