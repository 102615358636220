define("digital-style/routes/site/characters", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Route.extend({
        actions: {
            // run this after transition is over
            didTransition: function didTransition() {
                //console.log('intro-1 done');

                // wait a little for DOM to be really ready
                setTimeout(function () {
                    _ember["default"].$("body").addClass("mainSiteBg");

                    // console.log(t.controllerFor('application').get('lastRoute'));
                    // t.controllerFor('application').send('setRoute', t.routeName);
                }, 50);
            },
            // run this before transition happens
            willTransition: function willTransition() {
                // Ember.$("body").removeClass("mainSiteBg");
                this.controllerFor('application').set('lastRoute', this.routeName);
                // console.log(this.controllerFor('application').get('lastRoute'));
            }
        }
    });
});