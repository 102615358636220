define('digital-style/models/ellen/02-goodsleep', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_2_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/ellen/elleng09.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/ellen/elleng14.png",
            type: '',
            paths: [{
                cta: null,
                path: 14
            }]
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/ellen/ellen16.png",
            type: '',
            paths: [{
                cta: null,
                path: 260
            }]
        }, {
            id: 260,
            points: 0,
            imgurl: "assets/img/bg/bg_school.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 16
            }]
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/ellen/ellen19.png",
            type: '',
            paths: [{
                cta: null,
                path: 165
            }]
        },
        //19.5
        {
            id: 165,
            points: 0,
            imgurl: "assets/img/ellen/ellen19.5.png",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/ellen/ellen20.png",
            type: '',
            paths: [{
                cta: null,
                path: 18
            }]
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/ellen/ellen21.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/ellen/elleng22.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/ellen/elleng23.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/ellen/elleng24.png",
            type: '',
            paths: [{
                cta: null,
                path: 262
            }]
        }, {
            id: 262,
            points: 0,
            imgurl: "assets/img/bg/bg_recess.png",
            type: 'bg',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/ellen/ellen25.png",
            type: '',
            paths: [{
                cta: 'Sure.',
                path: 23
            }, {
                cta: 'No way...',
                path: 25
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/ellen/ellen25a.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/ellen/ellen27.png",
            type: '',
            paths: [{
                cta: null,
                path: 26
            }]
        }, {
            id: 25,
            points: 0,
            imgurl: "assets/img/ellen/ellen26.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 26,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.png",
            type: '',
            paths: [{
                cta: 'Sure.',
                path: 27
            }, {
                cta: 'What\'s this for?',
                path: 76
            }, {
                cta: 'No, thanks.',
                path: 77
            }]
        }, {
            id: 27,
            points: 0,
            imgurl: "assets/img/ellen/ellen29.gif",
            type: '',
            paths: [{
                cta: null,
                path: 28
            }]
        }, {
            id: 28,
            points: 0,
            imgurl: "assets/img/ellen/ellen30.png",
            type: '',
            paths: [{
                cta: 'Why us?',
                path: 29
            }, {
                cta: 'You want us to look through a dumpster...?',
                path: 78
            }]
        }, {
            id: 29,
            points: 0,
            imgurl: "assets/img/ellen/ellen31.png",
            type: '',
            paths: [{
                cta: 'We\'ll do it.',
                path: 30
            }, {
                cta: 'This is sketchy.',
                path: 79
            }, {
                cta: 'Sorry, bud...',
                path: 80
            }]
        }, {
            id: 30,
            points: 0,
            imgurl: "assets/img/ellen/ellen32_accept.png",
            type: 'end3',
            paths: [{
                cta: null,
                path: 1001
            }]
        }, {
            id: 76,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.5b.png",
            type: '',
            paths: [{
                cta: null,
                path: 27
            }]
        }, {
            id: 77,
            points: 0,
            imgurl: "assets/img/ellen/ellen28.5c.png",
            type: '',
            paths: [{
                cta: 'Okay, what\'s up?',
                path: 27
            }, {
                cta: 'What\'s the project?',
                path: 76
            }, {
                cta: 'No, thanks.',
                path: 80
            }]
        }, {
            id: 78,
            points: 0,
            imgurl: "assets/img/ellen/ellen30.5b.png",
            type: '',
            paths: [{
                cta: null,
                path: 29
            }]
        }, {
            id: 79,
            points: 0,
            imgurl: "assets/img/ellen/ellen31.5b.png",
            type: '',
            paths: [{
                cta: 'Okay.',
                path: 30
            }, {
                cta: 'No, sorry.',
                path: 80
            }]
        }, {
            id: 80,
            points: 0,
            imgurl: "assets/img/ellen/ellen32_reject.png",
            type: 'end4',
            paths: [{
                cta: null,
                path: 1001
            }]
        },

        // New Paths below!
        // the addon starts at range 1000
        // the 3 branches go 1100 (art), 1200 (video games), 1300 (hangout)
        {
            id: 1001,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/school_01_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1002
            }]
        }, {
            id: 1002,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/school_02d_g.png",
            type: '',
            paths: [{
                cta: 'I have art lessons.',
                path: 1100
            }, {
                cta: 'I\'m gonna play video games.',
                path: 1200
            }, {
                cta: 'I\'m just gonna hang out.',
                path: 1300
            }]
        }, {
            id: 1100,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_00_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1101
            }]
        }, {
            id: 1101,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_01.png",
            type: '',
            paths: [{
                cta: null,
                path: 1102
            }]
        }, {
            id: 1102,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_02.png",
            type: '',
            paths: [{
                cta: null,
                path: 1103
            }]
        }, {
            id: 1103,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_03.png",
            type: '',
            paths: [{
                cta: null,
                path: 1104
            }]
        }, {
            id: 1104,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_04_g.gif",
            type: '',
            paths: [{
                cta: null,
                path: 1105
            }]
        }, {
            id: 1105,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_05_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1106
            }]
        }, {
            id: 1106,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_06_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1107
            }]
        }, {
            id: 1107,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_07d_g.png",
            type: '',
            paths: [{
                cta: "Draw the fruit coming to life and leaping out of the bowl.",
                path: 1110
            }, {
                cta: "Draw capuchin monkeys screaming and playing with the fruit.",
                path: 1108
            }]
        },
        // {
        //     cta: "Draw ... three bowls of fruit??",
        //     path: 1112
        // }
        {
            id: 1108,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_07.png",
            type: '',
            paths: [{
                cta: null,
                path: 1109
            }]
        }, {
            id: 1109,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_08_g.png",
            type: 'end5',
            paths: [
                // {
                //     cta: null,
                //     path: 1114
                // }
            ]
        }, {
            id: 1110,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_07.png",
            type: '',
            paths: [{
                cta: null,
                path: 1111
            }]
        }, {
            id: 1111,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/art_09_g.png",
            type: 'end5',
            paths: [
                // {
                //     cta: null,
                //     path: 1114
                // }
            ]
        },
        // {
        //     id: 1112,
        //     points: 0,
        //     imgurl: "assets/img/ellen/c2/addon/art_07.png",
        //     type: '',
        //     paths: [
        //         {
        //             cta: null,
        //             path: 1113
        //         }
        //     ]
        // },
        // {
        //     id: 1113,
        //     points: 0,
        //     imgurl: "assets/img/ellen/c2/addon/art_10_g.png",
        //     type: '',
        //     paths: [
        //         {
        //             cta: null,
        //             path: 1114
        //         }
        //     ]
        // },
        // {
        //     id: 1114,
        //     points: 0,
        //     imgurl: "assets/img/ellen/c2/addon/art_11_g.png",
        //     type: 'end5',
        //     paths: [
        //
        //     ]
        // },

        {
            id: 1200,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/vg_01.png",
            type: '',
            paths: [{
                cta: null,
                path: 1201
            }]
        }, {
            id: 1201,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/vg_02.gif",
            type: '',
            paths: [{
                cta: null,
                path: 1202
            }]
        }, {
            id: 1202,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/vg_03.gif",
            type: '',
            paths: [{
                cta: null,
                path: 1203
            }]
        }, {
            id: 1203,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/vg_04.gif",
            type: '',
            paths: [{
                cta: null,
                path: 1204
            }]
        }, {
            id: 1204,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/vg_05_g.gif",
            type: '',
            paths: [{
                cta: null,
                path: 1205
            }]
        }, {
            id: 1205,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/vg_06_g.png",
            type: 'end5',
            paths: []
        }, {
            id: 1300,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_01.png",
            type: '',
            paths: [{
                cta: null,
                path: 1301
            }]
        }, {
            id: 1301,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_02.png",
            type: '',
            paths: [{
                cta: null,
                path: 1302
            }]
        }, {
            id: 1302,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_03.png",
            type: '',
            paths: [{
                cta: null,
                path: 1303
            }]
        }, {
            id: 1303,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_04_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1304
            }]
        }, {
            id: 1304,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_05d_g.png",
            type: '',
            paths: [{
                cta: "[Get their attention politely]",
                path: 1305
            }, {
                cta: "[Play a funny song on your phone]",
                path: 1306
            }]
        }, {
            id: 1305,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_06_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1308
            }]
        }, {
            id: 1306,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_07_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1307
            }]
        }, {
            id: 1307,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_08_g.png",
            type: '',
            paths: [{
                cta: null,
                path: 1308
            }]
        }, {
            id: 1308,
            points: 0,
            imgurl: "assets/img/ellen/c2/addon/hang_09_g.png",
            type: 'end5',
            paths: []
        }]
    });
});