define('digital-style/routes/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        actions: {
            // run this code once the app finished loading
            didTransition: function didTransition() {
                //console.log('app done');

                var isLoggedIn = this.get('controller').get('loggedIn');

                if (isLoggedIn) {
                    this.transitionTo(this.get('currentPath'));
                } else {
                    this.transitionTo('site.main');
                }
                // wait a little for the DOM to be really ready
                setTimeout(function () {
                    // toggle app's class to on
                    $('#app').toggleClass('on off');
                    $('#master').css({ 'width': window.innerWidth + 'px', 'height': window.innerHeight });
                }, 100);
            },
            willTransition: function willTransition() {
                this.get('controller').set('loggedIn', true);
            }
        }

    });
});
/*globals $ */