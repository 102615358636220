define('digital-style/routes/comic/ellen/04-help-homework', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        controllerName: 'comic/story',
        // comic model to build the comic
        model: function model() {
            return this.store.find('comic');
        },
        actions: {
            // run this once the comic route is loaded
            didTransition: function didTransition() {
                var t = this;

                // show scroll instructions
                setTimeout(function () {
                    // fix size
                    $('body').css({ 'width': window.innerWidth + 'px' });

                    t.controller.set('modelToUse', 'ellen/04-help-homework');

                    // clear all previous comic panels
                    t.store.unloadAll('comic');

                    // add the first panel of the model
                    t.get('controller').send('addPanel', 0); //51

                    // remove scroll lock
                    $('#comic').removeClass('lock');

                    // wait for DOM to be ready
                    $(document).ready(function () {
                        $(window).scroll(function () {
                            setTimeout(function () {
                                t.get('controller').send('topBannerScrollHandler');
                                t.get('controller').send('ellenLearningPointsScrollHandler');
                            }, 2000);
                        });
                    });
                }, 100);
            },

            learningPoint: function learningPoint() {
                console.log("test");
            }
        }
    });
});
/*globals $ */