define('digital-style/models/darby/c2', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/darby-c2/c2_title.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 1
            }]
        }, {
            id: 1,
            points: 0,
            imgurl: "assets/img/darby-c2/c2p1.png",
            type: '',
            paths: [{
                cta: null,
                path: 2
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "assets/img/darby-c2/c2p2d.png",
            type: '',
            paths: [{
                cta: "What's Up?",
                path: 3
            }, {
                cta: "Lets play video games!",
                path: 4
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "assets/img/darby-c2/c2p3.png",
            type: '',
            paths: [{
                cta: null,
                path: 4
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "assets/img/darby-c2/c2p4.png",
            type: '',
            paths: [{
                cta: null,
                path: 45
            }]
        }, {
            id: 45,
            points: 0,
            imgurl: "assets/img/darby-c2/c2p045.png",
            type: 'end',
            paths: []
        }, {
            id: 5,
            points: 0,
            imgurl: "assets/img/darby-c2/junk/c2p05.png",
            type: '',
            paths: [{
                cta: null,
                path: 6
            }]
        }, {
            id: 6,
            points: 0,
            imgurl: "assets/img/darby-c2/junk/c2p06.png",
            type: '',
            paths: [{
                cta: null,
                path: 7
            }]
        }, {
            id: 7,
            points: 0,
            imgurl: "assets/img/darby-c2/junk/c2p07.png",
            type: 'end',
            paths: []
        }, {
            id: 8,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p08.png",
            type: '',
            paths: [{
                cta: null,
                path: 9
            }]
        }, {
            id: 80,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p08.png",
            type: 'end',
            paths: []
        }, {
            id: 81,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p08.png",
            type: '',
            paths: [{
                cta: null,
                path: 91
            }]
        }, {
            id: 9,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 10
            }]
        }, {
            id: 91,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p09.png",
            type: '',
            paths: [{
                cta: null,
                path: 101
            }]
        }, {
            id: 10,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p10.gif",
            type: 'end',
            paths: []
        }, {
            id: 101,
            points: 0,
            imgurl: "assets/img/darby-c2/housecraft/c2p101.gif",
            type: 'end',
            paths: []
        }, {
            id: 11,
            points: 0,
            imgurl: "assets/img/darby-c2/antivirus/c2p11.png",
            type: '',
            paths: [{
                cta: null,
                path: 12
            }]
        }, {
            id: 12,
            points: 0,
            imgurl: "assets/img/darby-c2/antivirus/c2p12.png",
            type: '',
            paths: [{
                cta: null,
                path: 13
            }]
        }, {
            id: 13,
            points: 0,
            imgurl: "assets/img/darby-c2/antivirus/c2p13.gif",
            type: 'end',
            paths: []
        }, {
            id: 14,
            points: 0,
            imgurl: "assets/img/darby-c2/blog/c2p14.png",
            type: '',
            paths: [{
                cta: null,
                path: 15
            }]
        }, {
            id: 15,
            points: 0,
            imgurl: "assets/img/darby-c2/blog/c2p15.png",
            type: 'end',
            paths: []
        }, {
            id: 16,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p16.gif",
            type: '',
            paths: [{
                cta: null,
                path: 17
            }]
        }, {
            id: 17,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p17.png",
            type: 'end',
            paths: []
        }, {
            id: 18,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p18.png",
            type: '',
            paths: [{
                cta: null,
                path: 19
            }]
        }, {
            id: 19,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p19.png",
            type: '',
            paths: [{
                cta: null,
                path: 20
            }]
        }, {
            id: 20,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p20.png",
            type: '',
            paths: [{
                cta: null,
                path: 21
            }]
        }, {
            id: 21,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p21.png",
            type: '',
            paths: [{
                cta: null,
                path: 22
            }]
        }, {
            id: 22,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p22.png",
            type: '',
            paths: [{
                cta: null,
                path: 23
            }]
        }, {
            id: 23,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p23.png",
            type: '',
            paths: [{
                cta: null,
                path: 24
            }]
        }, {
            id: 24,
            points: 0,
            imgurl: "assets/img/darby-c2/translate/c2p24.gif",
            type: 'end2',
            paths: []
        }]
    });
});