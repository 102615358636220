define('digital-style/models/ellen/04-help-homework', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_4_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 51
            }]
        }, {
            id: 51,
            points: 0,
            imgurl: "assets/img/ellen/ellen53.png",
            type: '',
            paths: [{
                cta: null,
                path: 52
            }]
        }, {
            id: 52,
            points: 0,
            imgurl: "assets/img/ellen/ellen54.png",
            type: '',
            paths: [{
                cta: null,
                path: 53
            }]
        }, {
            id: 53,
            points: 0,
            imgurl: "assets/img/ellen/ellen55.png",
            type: '',
            paths: [{
                cta: null,
                path: 54
            }]
        }, {
            id: 54,
            points: 0,
            imgurl: "assets/img/ellen/ellen56.png",
            type: '',
            paths: [{
                cta: null,
                path: 55
            }]
        }, {
            id: 55,
            points: 0,
            imgurl: "assets/img/ellen/ellen57.png",
            type: '',
            paths: [{
                cta: null,
                path: 56
            }]
        }, {
            id: 56,
            points: 0,
            imgurl: "assets/img/ellen/ellen58.png",
            type: '',
            paths: [{
                cta: null,
                path: 57
            }]
        }, {
            id: 57,
            points: 0,
            imgurl: "assets/img/ellen/ellen59.png",
            type: '',
            paths: [{
                cta: null,
                path: 58
            }]
        }, {
            id: 58,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 59
            }]
        }, {
            id: 59,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 60
            }]
        }, {
            id: 60,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 61
            }]
        }, {
            id: 61,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 268
            }]
        }, {
            id: 268,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Continue Texting",
                path: 62
            }, {
                cta: "Text later",
                path: 269
            }]
        }, {
            id: 269,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 270
            }]
        }, {
            id: 270,
            points: 0,
            imgurl: "assets/img/ellen/ellen81nofight.png",
            type: '',
            paths: [{
                cta: null,
                path: 271
            }]
        }, {
            id: 271,
            points: 0,
            imgurl: "assets/img/ellen/ellen83.png",
            type: '',
            paths: [{
                cta: null,
                path: 272
            }]
        }, {
            id: 272,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 273
            }]
        }, {
            id: 273,
            points: 0,
            imgurl: "assets/img/ellen/ellen85.png",
            type: '',
            paths: [{
                cta: null,
                path: 274
            }]
        }, {
            id: 274,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 275
            }]
        }, {
            id: 275,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 276
            }]
        }, {
            id: 276,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 277
            }]
        }, {
            id: 277,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: 'end',
            paths: []
        }, {
            id: 62,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 63
            }]
        }, {
            id: 63,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 64
            }]
        }, {
            id: 64,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 65
            }]
        }, {
            id: 65,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 66
            }]
        }, {
            id: 66,
            points: 0,
            imgurl: "assets/img/ellen/ellen68.png",
            type: '',
            paths: [{
                cta: null,
                path: 67
            }]
        }, {
            id: 67,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 68
            }]
        }, {
            id: 68,
            points: 0,
            imgurl: "assets/img/ellen/ellen70_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 69
            }]
        }, {
            id: 69,
            points: 0,
            imgurl: "assets/img/ellen/ellen71_forgot.png",
            type: '',
            paths: [{
                cta: null,
                path: 72
            }]
        },
        /*
                {
                    id: 70,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 73
                        }
                    ]
                },
        */

        /*
                {
                    id: 71,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 72
                        }
                    ]
                },
        */

        {
            id: 72,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 73
            }]
        }, {
            id: 73,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 74
            }]
        }, {
            id: 74,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 75
            }]
        }, {
            id: 75,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 244
            }]
        }, {
            id: 244,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 245
            }]
        }, {
            id: 245,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 246
            }]
        }, {
            id: 246,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }]
    });
});