define('digital-style/components/patoo-journal', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		page_counter: 0,
		// modelToUse: 'spaghetti',
		// init: function() {
		// 	console.log('init');
		// },

		actions: {
			onStart: function onStart() {
				console.log('init start');
			},

			previousImg: function previousImg() {
				var store = this.get('targetObject.store');
				store.find('spaghetti', 1).then(function (arr) {
					var currentPage = nameToNum[arr.get('currentPage')];
					console.log("L button" + currentPage);
					document.getElementById('journalpg' + currentPage).style.display = 'none';

					var newPg = currentPage - 1;
					// var page = arr.get('page'+prevPg);
					// console.log("L button" + prevPg)

					// console.log(page);
					// console.log('journalpg'+currentPage)
					// document.getElementById('journalpg'+newPg).style.display = 'block';
					_ember['default'].$('#journalpg' + newPg).show();
					arr.set('currentPage', numToName[newPg]);

					console.log("currentPage " + currentPage + " newPg " + newPg);
					if (newPg > 1) {
						_ember['default'].$('#rightBtn').show();
						// console.log("greater than 1")
					}
					// else if ()
					else {
							_ember['default'].$('#leftBtn').hide();
							_ember['default'].$('#rightBtn').show();
							// console.log("ELSE greater than 1")
						}
				});
			},
			nextImg: function nextImg() {

				// var t = this;
				var store = this.get('targetObject.store');
				store.find('spaghetti', 1).then(function (arr) {
					// var currentPage = nextPanelConverter(arr.get('currentPage'));
					var currentPage = nameToNum[arr.get('currentPage')];
					console.log("R button" + currentPage);
					var nextPg = currentPage + 1;
					var page = arr.get(numToName[nextPg]);
					console.log("nextPage " + nextPg);

					console.log(page);
					if (page === 'true') {
						if (currentPage > 0) {
							_ember['default'].$('#leftBtn').show();
							// var prevPg = currentPage-1;
							// document.getElementById('journalpg'+currentPage).style.display = 'none';
							_ember['default'].$('#journalpg' + currentPage).hide();
						}
						//facilitates the button to not show when you've reached the end of the journal
						if (nextPg === 7) {
							_ember['default'].$('#rightBtn').hide();
						}
						// to facilitate if pages aren't unlocked yet, then right button auto hides
						if (nextPg + 1 < 8) {
							if (arr.get(numToName[nextPg + 1]) === 'false') {
								_ember['default'].$('#rightBtn').hide();
							}
							// var page2 = arr.get(numToName[nextPg+1])
						}
						// else

						console.log('journalpg' + page);
						document.getElementById('journalpg' + nextPg).style.display = 'block';
						// var newNum = nextPg;
						console.log("R button" + nextPg);
						arr.set('currentPage', numToName[nextPg]);
					} else {
						_ember['default'].$('#rightBtn').hide();
					}
				});
			},
			unlockPage: function unlockPage() {
				console.log('unlockPage');

				/*
    var store = this.get('targetObject.store');
    store.find('spaghetti', 1).then(function (arr) {
    	var page = arr.get('page2');
        console.log(page);
    	if (page === 'false'){
    		arr.set('page2', 'true');
    // 					document.getElementById("notificationBanner").style.display = 'inline';
    // // 					$('#how-to').delay(1000).fadeIn(1000);
    // 					$("#notificationBanner").delay(1600).animate({top: "+=200"}, 2000);
    // 					$(".darbyBtns").prop("disabled",true);
    // 					$(".DarbyUIButton").prop("disabled",true);
    	}
    	else{
    		//sets the overlay again, but currently doesn't know how to hide it
    // 					$('#how-to').css("z-index", "99");
    // 					$('#how-to').delay(1000).fadeIn(1000);
    	}
    });
    	*/
			},
			toggleWindow: function toggleWindow() {
				_ember['default'].$(".patooJournal").toggle();
				_ember['default'].$("#journalEntryNotification").hide();
				var store = this.get('targetObject.store');
				store.find('spaghetti', 1).then(function (arr) {
					var currentPage = nameToNum[arr.get('currentPage')];

					// // console.log("spagh"+arr.get('spaghetti')+"plate"+arr.get('plate'));
					// if (arr.get('spaghetti') === 'true' && arr.get('plate') === 'false'){

					// 	Ember.$('#rightBtn').hide();
					// }
					// else{
					// 	Ember.$('#rightBtn').show();
					// }

					//Show the correct last page, rather than just the first
					var totalPages = 7;
					while (totalPages != 0) {
						if (currentPage == totalPages) {
							_ember['default'].$('#journalpg' + totalPages).show();
						} else {
							_ember['default'].$('#journalpg' + totalPages).hide();
						}
						totalPages--;
					}

					if (currentPage + 1 < 8) {
						console.log("name " + arr.get(numToName[currentPage + 1]));
						if (arr.get(numToName[currentPage + 1]) === 'false') {
							_ember['default'].$('#rightBtn').hide();
						} else {
							_ember['default'].$('#rightBtn').show();
							// var page2 = arr.get(numToName[nextPg+1])
						}
					}

					// console.log("plate"+plate)
					if (currentPage === 1) {
						_ember['default'].$('#leftBtn').hide();
					} else if (currentPage === 7) {
						_ember['default'].$('#rightBtn').hide();
					}
					// console.log(typeof plate)
					/*	
      arr.get('spaghetti') === 'true' &&
      arr.get('plate') === 'false' &&				
     	arr.get('fruits') === 'false' &&
     arr.get('grains') === 'false' &&
     arr.get('dairy') === 'false' &&
     arr.get('protein') === 'false' &&
     arr.get('water') === 'false' 
     */
				});
				// document.getElementById("journalWindow").style.display = 'none';
			}

		}

	});

	var nameToNum = {
		"spaghetti": 1,
		"plate": 2,
		"fruits": 3,
		"grains": 4,
		"dairy": 5,
		"protein": 6,
		"water": 7
	};
	var numToName = {
		1: "spaghetti",
		2: "plate",
		3: "fruits",
		4: "grains",
		5: "dairy",
		6: "protein",
		7: "water"
	};

	// function nextPanelConverter(keyPhrase){
	// 	console.log(keyPhrase)
	// 	switch(keyPhrase){
	// 		case "spaghetti":
	// 			return 1;
	// 			break;
	// 		case "plate":
	// 			return 2;
	// 			break;
	// 		case "fruits":
	// 			return 3;
	// 			break;
	// 		case "grains":
	// 			return 4;
	// 			break;
	// 		case "dairy":
	// 			return 5;
	// 			break;
	// 		case "protein":
	// 			return 6;
	// 			break;
	// 		case "water":
	// 			return 7;
	// 			break;
	// 		default:
	// 			return 1;
	// 	}
	// }
});