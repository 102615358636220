define('digital-style/controllers/intro/intro-4', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        needs: ['application'],
        // content for intro.intro-4 route
        preload_1: 'assets/img/lab.png',
        preload_2: 'assets/img/screenfilter_withbackground.gif',
        preload_3: 'assets/img/retro/patuscreen_large.gif',
        copy_1: 'Which topic would you like to explore?',
        char_1: 'Darby',
        char_2: 'Ellen',
        char_1_img: 'assets/img/intro/mentalhealth.png',
        char_2_img: 'assets/img/intro/sleephygiene.png',
        // actions for intro.intro-2 route
        actions: {
            characterSelected: function characterSelected(c) {
                //console.log(c);

                // set the character selected in the application's controller
                if (c !== 'darby') {
                    this.get('controllers.application').set('character', c);

                    // transition to the next route
                    this.transitionToRoute('intro.intro-5');
                } else {
                    this.get('controllers.application').set('character', c);

                    // transition to the next route
                    this.transitionToRoute('comic.darby.c1');
                }
            }
        }
    });
});