define('digital-style/models/ellen/04-reject-text', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 0,
            points: 0,
            imgurl: "assets/img/ellen/chapter_4_titlecard.jpg",
            type: 'start',
            paths: [{
                cta: null,
                path: 149
            }]
        }, {
            id: 149,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 151
            }]
        },
        /*
        {
            id: 150,
            points: 0,
            imgurl: "assets/img/ellen/ellen52_bf.png",
            type: '',
            paths: [
                {
                    cta: null,
                    path: 151
                }
            ]
        },
        */
        {
            id: 151,
            points: 0,
            imgurl: "assets/img/ellen/ellen53_bf.png",
            type: '',
            paths: [{
                cta: null,
                path: 152
            }]
        }, {
            id: 152,
            points: 0,
            imgurl: "assets/img/ellen/ellen60_hw.png",
            type: '',
            paths: [{
                cta: null,
                path: 153
            }]
        }, {
            id: 153,
            points: 0,
            imgurl: "assets/img/ellen/ellen60.png",
            type: '',
            paths: [{
                cta: null,
                path: 154
            }]
        }, {
            id: 154,
            points: 0,
            imgurl: "assets/img/ellen/ellen61.png",
            type: '',
            paths: [{
                cta: null,
                path: 155
            }]
        }, {
            id: 155,
            points: 0,
            imgurl: "assets/img/ellen/ellen62.png",
            type: '',
            paths: [{
                cta: null,
                path: 156
            }]
        }, {
            id: 156,
            points: 0,
            imgurl: "assets/img/ellen/ellen63.png",
            type: '',
            paths: [{
                cta: null,
                path: 301
            }]
        }, {
            id: 301,
            points: 0,
            imgurl: "assets/img/ellen/ellen63d.png",
            type: '',
            paths: [{
                cta: "Text later",
                path: 302
            }, {
                cta: "Continue Texting",
                path: 157
            }]
        }, {
            id: 302,
            points: 0,
            imgurl: "assets/img/ellen/ellen80.png",
            type: '',
            paths: [{
                cta: null,
                path: 303
            }]
        }, {
            id: 303,
            points: 0,
            imgurl: "assets/img/ellen/ellen81.png",
            type: '',
            paths: [{
                cta: null,
                path: 304
            }]
        }, {
            id: 304,
            points: 0,
            imgurl: "assets/img/ellen/ellen82.jpg",
            type: '',
            paths: [{
                cta: null,
                path: 305
            }]
        }, {
            id: 305,
            points: 0,
            imgurl: "assets/img/ellen/ellen83cathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 306
            }]
        }, {
            id: 306,
            points: 0,
            imgurl: "assets/img/ellen/ellen84.png",
            type: '',
            paths: [{
                cta: null,
                path: 307
            }]
        }, {
            id: 307,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 308
            }]
        }, {
            id: 308,
            points: 0,
            imgurl: "assets/img/ellen/ellen86.png",
            type: '',
            paths: [{
                cta: null,
                path: 309
            }]
        }, {
            id: 309,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 310
            }]
        }, {
            id: 310,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 311
            }]
        }, {
            id: 311,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 312
            }]
        }, {
            id: 312,
            points: 0,
            imgurl: "assets/img/ellen/ellen76thankcathy.png",
            type: '',
            paths: [{
                cta: null,
                path: 313
            }]
        }, {
            id: 313,
            points: 0,
            imgurl: "assets/img/ellen/ellen77thankcathy.png",
            type: 'end',
            paths: []
        }, {
            id: 157,
            points: 0,
            imgurl: "assets/img/ellen/ellen64.png",
            type: '',
            paths: [{
                cta: null,
                path: 158
            }]
        }, {
            id: 158,
            points: 0,
            imgurl: "assets/img/ellen/ellen65.png",
            type: '',
            paths: [{
                cta: null,
                path: 159
            }]
        }, {
            id: 159,
            points: 0,
            imgurl: "assets/img/ellen/ellen66.png",
            type: '',
            paths: [{
                cta: null,
                path: 160
            }]
        }, {
            id: 160,
            points: 0,
            imgurl: "assets/img/ellen/ellen67.png",
            type: '',
            paths: [{
                cta: null,
                path: 161
            }]
        }, {
            id: 161,
            points: 0,
            imgurl: "assets/img/ellen/ellen68_spazz.png",
            type: '',
            paths: [{
                cta: null,
                path: 162
            }]
        }, {
            id: 162,
            points: 0,
            imgurl: "assets/img/ellen/ellen69.png",
            type: '',
            paths: [{
                cta: null,
                path: 163
            }]
        }, {
            id: 163,
            points: 0,
            imgurl: "assets/img/ellen/ellen70.png",
            type: '',
            paths: [{
                cta: null,
                path: 164
            }]
        }, {
            id: 164,
            points: 0,
            imgurl: "assets/img/ellen/ellen71.png",
            type: '',
            paths: [{
                cta: null,
                path: 167
            }]
        },
        /*
                {
                    id: 165,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen72.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 166
                        }
                    ]
                },
                {
                    id: 166,
                    points: 0,
                    imgurl: "assets/img/ellen/ellen73.png",
                    type: '',
                    paths: [
                        {
                            cta: null,
                            path: 167
                        }
                    ]
                },
        */
        {
            id: 167,
            points: 0,
            imgurl: "assets/img/ellen/ellen74.png",
            type: '',
            paths: [{
                cta: null,
                path: 168
            }]
        }, {
            id: 168,
            points: 0,
            imgurl: "assets/img/ellen/ellen75.png",
            type: '',
            paths: [{
                cta: null,
                path: 170
            }]
        }, {
            id: 170,
            points: 0,
            imgurl: "assets/img/ellen/ellen755.png",
            type: '',
            paths: [{
                cta: null,
                path: 171
            }]
        }, {
            id: 171,
            points: 0,
            imgurl: "assets/img/ellen/ellen76.png",
            type: '',
            paths: [{
                cta: null,
                path: 255
            }]
        }, {
            id: 255,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu1.png",
            type: '',
            paths: [{
                cta: null,
                path: 256
            }]
        }, {
            id: 256,
            points: 0,
            imgurl: "assets/img/ellen/ellen_patu2.png",
            type: '',
            paths: [{
                cta: null,
                path: 257
            }]
        }, {
            id: 257,
            points: 0,
            imgurl: "assets/img/ellen/ellen77.png",
            type: '',
            paths: [{
                cta: null,
                path: 258
            }]
        }, {
            id: 258,
            points: 0,
            imgurl: "assets/img/ellen/ellen78.png",
            type: '',
            paths: [{
                cta: null,
                path: 259
            }]
        }, {
            id: 259,
            points: 0,
            imgurl: "assets/img/ellen/ellen79.png",
            type: 'end',
            paths: []
        }]
    });
});