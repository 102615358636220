define('digital-style/routes/comic/spaghetti/c4', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		controllerName: 'comic/spaghetti/c4',
		// comic model to build the comic
		model: function model() {
			return this.store.find('comic');
		},
		actions: {
			// run this once the comic route is loaded
			didTransition: function didTransition() {
				var t = this;
				setTimeout(function () {

					window.scrollTo(0, 0);
					_ember['default'].$('#comic').addClass('lock');
					// 				t.send('startTime');
					_ember['default'].$('body').css({ 'width': window.innerWidth + 'px' });

					t.controller.set('modelToUse', 'spaghetti/c4');

					// clear all previous comic panels
					t.store.unloadAll('comic');

					// add the first panel of the model
					t.get('controller').send('addPanel', 0);

					document.body.style.background = '#e8c390';

					// remove scroll lock
					_ember['default'].$('#comic').removeClass('lock');

					// wait for DOM to be ready
					_ember['default'].$(document).ready(function () {
						//                     ts.init();
						t.store.find('spaghetti', 1).then(function (arr) {
							arr.set('spaghetti', 'true');
							arr.set('plate', 'true');
							arr.set('fruits', 'true');
							arr.set('grains', 'true');
							arr.set('dairy', 'true');
							arr.set('protein', 'true');
							arr.set('water', 'true');
						});
						_ember['default'].$(".toggleJournalBtn").show();

						var notified = false;
						var documentHeightAtFirstChoice;
						_ember['default'].$(window).scroll(function () {
							setTimeout(function () {
								t.get('controller').send('topBannerScrollHandler');
							}, 2000);

							if (notified === false) {
								var documentHeight = _ember['default'].$(document).height() - 300;

								if (_ember['default'].$(window).scrollTop() + _ember['default'].$(window).height() >= documentHeight) {
									notified = true;
									documentHeightAtFirstChoice = _ember['default'].$(document).height();
									console.log(documentHeightAtFirstChoice);

									_ember['default'].$("#choiceInstructions").fadeIn(1000);
								}
							}

							//once the notification shows up and the users clicks, this will make the banner hide again
							if (notified === true) {
								if (_ember['default'].$(document).height() >= documentHeightAtFirstChoice + 150) {
									_ember['default'].$("#choiceInstructions").fadeOut(1000);
								}
							}
						});
					});
				}, 100);

				this.store.find('spaghetti', 1).then(function (arr) {
					//Dev mode to set all the things if necessary

					arr.set('test1', true);
				});
			},

			recipeHandler: function recipeHandler() {
				this.store.find('spaghetti', 1).then(function (arr) {
					//Dev mode to set all the things if necessary
					console.log("here route");
					arr.set('recipeCalculator', 2);
				});
			},

			willTransition: function willTransition() {
				document.body.style.background = '#FFFFFF';
			}

		}

	});
});