define('digital-style/models/kevin', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        points: _emberData['default'].attr('number'),
        imgurl: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        paths: _emberData['default'].attr()
    }).reopenClass({
        FIXTURES: [{
            id: 1,
            points: 0,
            imgurl: "//placehold.it/350x150",
            type: 'start',
            paths: [{
                cta: 'Alright.',
                path: 2
            }, {
                cta: 'Other path.',
                path: 3
            }]
        }, {
            id: 2,
            points: 0,
            imgurl: "//placehold.it/200x100",
            type: '',
            paths: [{
                cta: 'Sleep.',
                path: 4
            }, {
                cta: 'Study more.',
                path: 5
            }]
        }, {
            id: 3,
            points: 0,
            imgurl: "//placehold.it/350x150",
            type: '',
            paths: [{
                cta: 'Oh...',
                path: 5
            }]
        }, {
            id: 4,
            points: 0,
            imgurl: "//placehold.it/350x150",
            type: '',
            paths: [{
                cta: 'Yeah!',
                path: 6
            }]
        }, {
            id: 5,
            points: 0,
            imgurl: "//placehold.it/350x150",
            type: 'end',
            paths: []
        }, {
            id: 6,
            points: 0,
            imgurl: "//placehold.it/350x150",
            type: 'end',
            paths: []
        }]
    });
});